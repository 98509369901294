import { Text, HStack } from '@chakra-ui/react'
import HelpTextPopover from '@/components/atoms/HelpTextPopover'

type BoxHeadingProps = {
  title: string
  caption?: string
  CallToActionComponent?: React.ReactNode
  tooltipMessage?: string | React.ReactNode
}

const BoxHeading = ({
  title,
  caption,
  CallToActionComponent,
  tooltipMessage,
}: BoxHeadingProps) => (
  <HStack justifyContent='space-between' alignItems='center' width='100%'>
    <HStack spacing={2}>
      <HelpTextPopover tooltipMessage={tooltipMessage} />
      <Text color='gray.500' fontSize='md' fontWeight='semibold'>
        {title}
      </Text>
      <Text fontSize='sm' color='gray.500'>
        {caption}
      </Text>
    </HStack>
    {CallToActionComponent}
  </HStack>
)

export default BoxHeading
