import { HStack, Text, TextProps } from '@chakra-ui/react'
import { ActionFieldComponent, HelpTextPopover } from '@/components/atoms'

export type ActionComponent = {
  ariaLabel: string
  icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  isDisabled?: boolean
  isHidden?: boolean
  isOpen?: boolean
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  tooltipLabel: string
}

type ActionFieldComponentProps = {
  label?: string
  labelTextProps?: TextProps
  leftActions?: ActionComponent[]
  rightActions?: ActionComponent[]
  tooltipMessage?: string | React.ReactNode
  value?: string | number
  valueTextProps?: TextProps
}

const DataActionField = ({
  label,
  labelTextProps,
  leftActions,
  rightActions,
  tooltipMessage,
  value,
  valueTextProps,
}: ActionFieldComponentProps) => {
  if (!value && !leftActions?.length && !rightActions?.length) return null
  return (
    <HStack spacing={2} width='100%' overflow='hidden'>
      {!!label && (
        <HStack spacing={1} width='fit-content'>
          <HelpTextPopover tooltipMessage={tooltipMessage} />
          <Text
            fontSize='sm'
            color='black'
            fontWeight='semibold'
            whiteSpace='nowrap'
            {...labelTextProps}
          >
            {label}:
          </Text>
        </HStack>
      )}
      {leftActions?.map((action, index) => (
        <ActionFieldComponent key={index} {...action} />
      ))}
      {!!value && (
        <Text
          color='iris.500'
          fontWeight='semibold'
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          {...valueTextProps}
        >
          {value}
        </Text>
      )}
      {rightActions?.map((action, index) => (
        <ActionFieldComponent key={index} {...action} />
      ))}
    </HStack>
  )
}

export default DataActionField
