import {
  chakra,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { BiX } from 'react-icons/bi'

type DialogModalTemplateProps = {
  children: React.ReactNode
  modalContentProps?: ModalContentProps
  isOpen: boolean
  onCloseDialog: () => void
  title: string
}

const DialogModalTemplate = ({
  children,
  isOpen,
  modalContentProps,
  onCloseDialog,
  title,
}: DialogModalTemplateProps) => (
  <Modal isOpen={isOpen} onClose={onCloseDialog} isCentered>
    <ModalOverlay />
    <ModalContent minWidth='auto' maxWidth='512px' {...modalContentProps}>
      <ModalHeader textTransform='capitalize'>
        <HStack justifyContent='space-between'>
          <chakra.span color='russianViolet.600'>{title}</chakra.span>
          <IconButton
            aria-label='close modal'
            icon={<Icon as={BiX} boxSize='18px' />}
            onClick={onCloseDialog}
            variant='modal-close-button'
          />
        </HStack>
      </ModalHeader>
      {children}
    </ModalContent>
  </Modal>
)

export default DialogModalTemplate
