/* 
    This hook is projected to work with the details page
    It will receive all the arguments from a normal usage of useQuery
    and will return the same result, but with the addition of the breadcrumb.
*/

import { get } from 'lodash'
import { useQuery, UseQueryOptions } from 'react-query'
import useBreadCrumb from './useBreadCrumb'

type QueryOptions<T> = UseQueryOptions<T> & {
  isEditing?: boolean
}

const useQueryWithBreadcrumb = <T>(
  breadcrumbField: string,
  { queryKey, queryFn, enabled, isEditing, ...options }: QueryOptions<T>
) => {
  const useQueryObject = useQuery<T, any, T, string | readonly any[]>({
    queryKey,
    queryFn,
    ...options,
    // isEditing will block any auto refetch if the user is editing the form
    enabled: isEditing ? false : enabled,
    // disables the auto refetch if the query returned an error
    refetchInterval: (data, query) => (query.state.error ? false : 5000),
  })
  const { isLoading, data, isError } = useQueryObject
  useBreadCrumb({
    name: get(data, breadcrumbField, '') as string,
    isLoading,
    isError,
  })

  return useQueryObject
}

export default useQueryWithBreadcrumb
