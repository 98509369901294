import { IconType } from 'react-icons'
import {
  BiServer,
  BiTask,
  BiAddToQueue,
  BiHeadphone,
  BiData,
  BiLibrary,
} from 'react-icons/bi'
import { MdDashboard } from 'react-icons/md'

import { docsUrl } from '@/commons/helpers/LocalEnvData'

type SectionItemProps = {
  dataTour?: string
  externalUrl?: string
  linkItems?: {
    name: string
    route: string
    dataTour?: string
    requiredPermissions?: string[]
  }[]
  name: string
  requiredPermissions?: string[]
  rootUrl?: string
  route?: string
  SectionIcon: IconType
  subSection: string
}

type DashboardItemProps = {
  SectionIcon: IconType
  name: string
  route: string
  subSection: string
}

const DashboardItems: DashboardItemProps[] = [
  {
    name: 'Home',
    SectionIcon: MdDashboard,
    route: '/',
    subSection: 'dashboards',
  },
]

const SectionItems: SectionItemProps[] = [
  {
    name: 'Clusters',
    route: '/cluster',
    SectionIcon: BiServer,
    subSection: 'apps',
    dataTour: 'sidebar-clusters',
    requiredPermissions: ['cluster:graphql:view'],
  },
  {
    name: 'Storage',
    route: '/storage',
    SectionIcon: BiData,
    subSection: 'apps',
  },
  {
    name: 'Licenses',
    rootUrl: '/lm',
    requiredPermissions: [
      'license-manager:config:view',
      'license-manager:inventory:view',
    ],
    linkItems: [
      {
        name: 'License Configurations',
        route: '/lm/configurations',
        requiredPermissions: ['license-manager:config:view'],
      },
      {
        name: 'Inventory',
        route: '/lm/inventory',
        requiredPermissions: ['license-manager:inventory:view'],
      },
    ],
    SectionIcon: BiTask,
    subSection: 'apps',
  },
  {
    name: 'Jobs',
    rootUrl: '/jobs',
    dataTour: 'sidebar-jobs',
    requiredPermissions: [
      'jobbergate:job-submissions:view',
      'jobbergate:job-scripts:view',
      'jobbergate:job-templates:view',
    ],
    linkItems: [
      {
        name: 'Job Script Templates',
        route: '/jobs/job-script-templates',
        requiredPermissions: ['jobbergate:job-templates:view'],
      },
      {
        name: 'Job Scripts',
        route: '/jobs/job-scripts',
        requiredPermissions: ['jobbergate:job-scripts:view'],
      },
      {
        name: 'Job Submissions',
        route: '/jobs/job-submissions',
        requiredPermissions: ['jobbergate:job-submissions:view'],
      },
    ],
    SectionIcon: BiAddToQueue,
    subSection: 'apps',
  },
  {
    name: 'Support',
    route: '/support',
    SectionIcon: BiHeadphone,
    subSection: 'helpdesk',
    dataTour: 'sidebar-support',
  },
  {
    name: 'Vantage Docs',
    SectionIcon: BiLibrary,
    subSection: 'helpdesk',
    externalUrl: docsUrl,
  },
]

export { DashboardItems, SectionItems }
