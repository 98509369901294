import { JSONContent } from '@tiptap/react'

const convertTipTapObjectToText = (data: JSONContent[]): string =>
  data
    .map((node) => {
      if (node.type === 'paragraph' && node.content) {
        return node.content
          .map((item) => {
            if (item.type === 'paragraph') {
              // Recursively call the function to handle nested paragraphs
              return convertTipTapObjectToText([item])
            }
            if (item.type === 'text') {
              return item.text || ''
            } else if (item.type === 'mention') {
              return `(${item?.attrs?.label})[${item?.attrs?.id}]`
            }
            return ''
          })
          .join('')
      }
      return ''
    })
    .join('\n')

export default convertTipTapObjectToText
