import {
  Box,
  Icon,
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
} from '@chakra-ui/react'

import { checkForNecessaryPermissions } from '@/commons/helpers'
import { useKeycloak } from '@/hooks'
import { IconType } from 'react-icons'

type ActionIconButtonProps = Omit<IconButtonProps, 'icon'> & {
  icon: IconType
  requiredPermissions?: string[]
  tooltipProps?: Omit<TooltipProps, 'children'>
}

const ActionIconButton = ({
  icon,
  isDisabled,
  requiredPermissions,
  tooltipProps,
  ...props
}: ActionIconButtonProps) => {
  const { userPermissions } = useKeycloak()
  const hasNecessaryPermissions = checkForNecessaryPermissions(
    userPermissions,
    requiredPermissions
  )
  const isButtonDisabled = isDisabled ? true : !hasNecessaryPermissions
  const { label, ...resTooltipProps } = tooltipProps ?? {}
  const tooltipLabel = !hasNecessaryPermissions
    ? "You don't have permission to perform this action."
    : label

  return (
    <Tooltip
      hasArrow
      label={tooltipLabel}
      placement='top-end'
      isDisabled={!isButtonDisabled}
      {...resTooltipProps}
    >
      <Box width='fit-content'>
        <IconButton
          {...props}
          _active={{
            backgroundColor: 'transparent',
          }}
          _disabled={{
            opacity: 0.5,
            cursor: 'not-allowed',
          }}
          _hover={{
            backgroundColor: 'transparent',
          }}
          height='fit-content'
          icon={<Icon as={icon} boxSize='16px' />}
          isDisabled={isButtonDisabled}
          minWidth='fit-content'
          variant='ghost'
        />
      </Box>
    </Tooltip>
  )
}

export default ActionIconButton
