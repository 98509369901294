import { Box, HStack, Text, TextProps } from '@chakra-ui/react'
import { HelpTextPopover, TextFieldComponent } from '@/components/atoms'

type TextListProps = {
  label?: string
  labelTextProps?: TextProps
  tooltipMessage?: string | React.ReactNode
  value?: string | number
  valueTextProps?: TextProps
}

type DataTextListFieldProps = Omit<
  TextListProps,
  'value' | 'valueTextProps'
> & {
  value?: TextListProps[]
}

const DataTextListField = ({
  label,
  value,
  labelTextProps,
  tooltipMessage,
}: DataTextListFieldProps) => {
  if (!value || value.length === 0) return null
  return (
    <Box>
      {!!label && (
        <HStack spacing={1} width='fit-content'>
          <HelpTextPopover tooltipMessage={tooltipMessage} />
          <Text
            fontSize='sm'
            color='black'
            fontWeight='semibold'
            whiteSpace='nowrap'
            {...labelTextProps}
          >
            {label}:
          </Text>
        </HStack>
      )}
      {value.map((valueRow, index) => (
        <TextFieldComponent key={index} {...valueRow} />
      ))}
    </Box>
  )
}

export default DataTextListField
