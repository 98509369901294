import { RouteProps, RouteComponentProps, Route } from 'react-router-dom'
import { usePageTracking, useKeycloak } from '@/hooks'

interface AdminRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
  exact?: boolean
  path: string
}

const AdminRoute = ({ component, path, ...args }: AdminRouteProps) => {
  usePageTracking()
  const { isUserAdmin } = useKeycloak()
  return isUserAdmin ? (
    <Route component={component} path={path} {...args} />
  ) : null
}

export default AdminRoute
