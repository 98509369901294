import { useEffect, useRef } from 'react'
import { get } from 'lodash'
import {
  FormControl,
  FormErrorMessage,
  Icon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react'
import { Field, FieldProps as FormikFieldProps } from 'formik'
import { BiCaretUp, BiCaretDown } from 'react-icons/bi'
import { FieldBaseProps } from '@/commons/types'
import { FieldLabel } from '@/components/atoms'

export interface FieldNumberInputProps extends FieldBaseProps {
  placeholder?: string
  min?: number
  max?: number
  focusOnMount?: boolean
  isInteger?: boolean
}

const FieldNumberInput = ({
  focusOnMount,
  hideErrorMessage,
  isDisabled,
  label,
  LabelComponent,
  labelProps,
  max,
  min,
  name,
  placeholder,
  subLabel,
  tooltipMessage,
  isInteger,
}: FieldNumberInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (focusOnMount) {
      inputRef?.current?.focus()
    }
  }, [inputRef, focusOnMount])

  return (
    <Field name={name}>
      {({ field, form }: FormikFieldProps) => {
        const errorMessage = get(form, `errors.${name}`),
          hasError = !!(errorMessage && get(form, `touched.${name}`))

        const handleChange = (valueAsString: string) => {
          let formattedValue = valueAsString
          if (isInteger) {
            // handle with decimal point
            if (valueAsString.includes('.'))
              formattedValue = formattedValue.replace('.', '')
            // handle with exponential symbol
            if (valueAsString.includes('e'))
              formattedValue = formattedValue.replace('e', '')
          }
          // handle with minus sign
          if (!isNaN(Number(min)) && Number(min) >= 0)
            formattedValue = formattedValue.replace('-', '')
          form.setFieldValue(name, formattedValue)
        }

        return (
          <FormControl isInvalid={hasError}>
            {label && (
              <FieldLabel
                hasError={hasError}
                label={label}
                LabelComponent={LabelComponent}
                labelProps={labelProps}
                name={name}
                subLabel={subLabel}
                tooltipMessage={tooltipMessage}
              />
            )}

            <NumberInput
              {...field}
              id={name}
              isDisabled={isDisabled}
              max={max}
              min={min}
              onChange={handleChange}
              border='none'
            >
              <NumberInputField
                // Applying a box shadow is the only way to change the autofill background color
                _autofill={{
                  // iris.50
                  boxShadow: '0 0 0 1000px #EEECFB inset',
                  backgroundColor: 'transparent !important',
                }}
                _disabled={{
                  backgroundColor: 'gray.100',
                  opacity: 0.5,
                  cursor: 'not-allowed',
                  color: 'gray.600',
                  borderColor: 'gray.300',
                }}
                _focus={{
                  borderColor: 'iris.500',
                  boxShadow: 'none',
                }}
                _invalid={{
                  borderColor: 'red.500',
                  color: 'red.600',
                  boxShadow: 'none',
                  _placeholder: {
                    color: 'red.500',
                  },
                }}
                _placeholder={{
                  fontSize: 'sm',
                  color: 'blackAlpha.500',
                }}
                borderColor='gray.200'
                borderRadius='8px'
                borderWidth='1px'
                color='gray.700'
                placeholder={placeholder}
                ref={inputRef}
              />
              <NumberInputStepper>
                <NumberIncrementStepper>
                  <Icon as={BiCaretUp} />
                </NumberIncrementStepper>
                <NumberDecrementStepper>
                  <Icon as={BiCaretDown} />
                </NumberDecrementStepper>
              </NumberInputStepper>
            </NumberInput>

            {!hideErrorMessage && (
              <FormErrorMessage fontSize='xs' fontWeight='normal' marginTop={1}>
                {errorMessage}
              </FormErrorMessage>
            )}
          </FormControl>
        )
      }}
    </Field>
  )
}

export default FieldNumberInput
