import { useQuery } from 'react-query'

import { useKeycloak } from '@/hooks'
import { fetchWatchersAndMentions } from './services'

const useNotifications = () => {
  const { hasOrganizationInToken, user } = useKeycloak()
  const email = user?.profile?.email

  const { data: notifications, refetch } = useQuery(
    'notifications',
    () => fetchWatchersAndMentions(email),
    {
      enabled: hasOrganizationInToken && !!email,
      refetchInterval: 30 * 1000,
    }
  )

  const uncheckedAlertsQtd = notifications?.filter((notification) => {
    if (notification?.actionType === 'alert') {
      return !notification?.checked
    }
    return !notification?.alerts[0].checked
  }).length as number

  return {
    notifications,
    uncheckedAlertsQtd,
    refetchNotifications: refetch,
  }
}

export default useNotifications
