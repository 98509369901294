import { useRef } from 'react'
import {
  Button,
  Link,
  Spinner,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
} from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { jsonToGraphQLQuery } from 'json-to-graphql-query'
import { BiPaperclip, BiSolidDownload } from 'react-icons/bi'

import { Axios, base64ToFile, convertByteSize } from '@/commons/helpers'
import { Attachments } from '@/commons/interfaces'
import { useToasts } from '@/hooks'

const downloadFile = (attachmentId: number) =>
  Axios.post<{
    data: {
      attachmentBinary: string
    }
    errors?: any[]
  }>('/sos/graphql', {
    query: jsonToGraphQLQuery({
      query: {
        attachmentBinary: {
          __args: { attachmentId },
        },
      },
    }),
  })

const makeFileBlob = (isTextFile: boolean, binary: string, type: string) => {
  if (isTextFile) {
    return new Blob([binary], {
      type,
    })
  }
  return base64ToFile(binary, type)
}

const CommentFileTag = ({ filename, id, size, type }: Attachments) => {
  const linkRef = useRef<HTMLAnchorElement>(null)
  const { renderErrorToast } = useToasts({
    errorDescription: 'Error downloading file',
  })

  const { isFetching, refetch } = useQuery(
    ['downloadFile', id],
    () => downloadFile(id),
    {
      enabled: false,
      onSuccess: ({ data }) => {
        try {
          if (data.errors?.length) {
            throw new Error(data.errors[0].message)
          }
          const binary = data.data.attachmentBinary
          if (!binary || !linkRef.current) {
            throw new Error('No binary found')
          }
          const isTextFile = type === 'text/plain'
          const fileBlob = makeFileBlob(isTextFile, binary, type)
          if (!fileBlob) {
            throw new Error('Error creating file')
          }
          const url = window.URL.createObjectURL(fileBlob)
          linkRef.current.href = url
          linkRef.current.click()
        } catch (error) {
          renderErrorToast()
          console.error(error)
        }
      },
      onError: (error) => {
        renderErrorToast()
        console.error(error)
      },
    }
  )
  return (
    <>
      <Link ref={linkRef} download={filename} display='none' />
      <Tag
        _hover={{ backgroundColor: 'gray.600' }}
        as={Button}
        backgroundColor='gray.500'
        borderRadius='full'
        color='white'
        height='fit-content'
        onClick={() => refetch()}
        size='md'
      >
        <TagLeftIcon
          as={BiPaperclip}
          boxSize='14px'
          marginRight={1}
          transform='rotate(-90deg)'
        />
        <TagLabel
          fontWeight='semibold'
          height={5}
          lineHeight={4}
          paddingTop='2px'
        >
          {`${filename} - ${convertByteSize(size)}`}
        </TagLabel>
        {!isFetching && (
          <TagRightIcon as={BiSolidDownload} boxSize='14px' marginLeft={1} />
        )}
        {isFetching && <Spinner boxSize='14px' color='white' marginLeft={1} />}
      </Tag>
    </>
  )
}

export default CommentFileTag
