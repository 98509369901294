import { useCallback } from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import { BiUpload, BiX } from 'react-icons/bi'
import {
  Center,
  Flex,
  FormControl,
  Icon,
  Tag,
  TagLabel,
  TagRightIcon,
  VStack,
} from '@chakra-ui/react'
import { FieldHelperProps, useField } from 'formik'
import { uniqBy } from 'lodash'

import { convertByteSize } from '@/commons/helpers'
import { FieldBaseProps } from '@/commons/types'
import { FieldLabel } from '@/components/atoms'

// use prop isDisabled from FieldBaseProps
type ModifiedDropzoneOptions = Omit<DropzoneOptions, 'disabled' | 'onDrop'>
type CustomDropzoneProps = {
  DropzoneMessageElement: React.ReactNode
  onDrop?: (
    acceptedFiles: File[],
    fieldHelpers: FieldHelperProps<File[]>
  ) => void
  onRemoveFile?: (file: File, fieldHelpers: FieldHelperProps<File[]>) => void
  showFiles?: boolean
}

export type FieldDropzoneProps = FieldBaseProps &
  ModifiedDropzoneOptions &
  CustomDropzoneProps

const FieldDropzone = ({
  accept,
  DropzoneMessageElement,
  hideErrorMessage,
  isDisabled,
  label,
  LabelComponent,
  labelProps,
  maxFiles,
  name,
  onDrop,
  onRemoveFile,
  showFiles,
  subLabel,
  tooltipMessage,
  ...dropProps
}: FieldDropzoneProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, fieldHelpers] = useField<File[]>(name)
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (onDrop) onDrop(acceptedFiles, fieldHelpers)
      else {
        const uniqFiles = uniqBy(field.value.concat(acceptedFiles), 'name')
        const filteredFilesListByMaxFiles = maxFiles
          ? uniqFiles.slice(-1 * maxFiles)
          : uniqFiles

        fieldHelpers.setValue(filteredFilesListByMaxFiles)
      }
    },
    [fieldHelpers, field.value, onDrop, maxFiles]
  )

  const handleRemoveFile = (
    event: React.MouseEvent<SVGElement, MouseEvent>,
    file: File
  ) => {
    event.stopPropagation()
    if (onRemoveFile) onRemoveFile(file, fieldHelpers)
    else {
      fieldHelpers.setValue(
        field.value.filter(({ name }) => name !== file.name)
      )
    }
  }

  const { getRootProps, getInputProps, isDragReject, isDragActive } =
    useDropzone({
      onDrop: handleDrop,
      accept,
      maxFiles,
      ...dropProps,
    })

  const backgroundColor = isDragReject
    ? 'red.50'
    : isDragActive
    ? 'iris.100'
    : 'iris.50'

  return (
    <FormControl>
      {label && (
        <FieldLabel
          label={label}
          LabelComponent={LabelComponent}
          labelProps={labelProps}
          name={name}
          subLabel={subLabel}
          tooltipMessage={tooltipMessage}
        />
      )}

      <Center
        cursor='pointer'
        border='1px dashed'
        borderColor={isDragReject ? 'red.500' : 'iris.500'}
        backgroundColor={backgroundColor}
        borderRadius='lg'
        padding={5}
        {...getRootProps()}
      >
        <VStack minHeight='50px' display='grid' width='100%'>
          {!showFiles && (
            <Center>
              <Flex
                alignItems='center'
                backgroundColor='iris.100'
                borderRadius='full'
                boxSize={10}
                justifyContent='center'
              >
                <Icon as={BiUpload} boxSize={4} color='iris.500' />
              </Flex>
            </Center>
          )}
          {showFiles && (
            <VStack alignItems='flex-start' spacing={1} width='100%'>
              {field.value.length > 0 &&
                field.value.map((file) => (
                  <Tag
                    key={file.name}
                    borderRadius='full'
                    backgroundColor='iris.500'
                  >
                    <TagLabel color='white' fontWeight='semibold'>
                      {`${file.name} - ${convertByteSize(file.size)}`}
                    </TagLabel>
                    <TagRightIcon
                      as={BiX}
                      boxSize='14px'
                      onClick={(event) => handleRemoveFile(event, file)}
                      cursor='pointer'
                      color='white'
                    />
                  </Tag>
                ))}
            </VStack>
          )}
          {DropzoneMessageElement}
          <input {...getInputProps()} />
        </VStack>
      </Center>
    </FormControl>
  )
}

export default FieldDropzone
