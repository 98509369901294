import { Button, HStack, ModalBody, ModalFooter, Text } from '@chakra-ui/react'

import FormActionButtons from '@/components/atoms/FormActionButtons'
import DialogModalTemplate from '@/components/atoms/DialogModalTemplate'

type CodeEditorDrawerUnsavedModalProps = {
  filename: string
  isOpen: boolean
  isSubmitting: boolean
  onClose: () => void
  onResetChanges: () => void
  onSaveFile: () => void
}

const CodeEditorDrawerUnsavedModal = ({
  filename,
  isOpen,
  isSubmitting,
  onClose,
  onResetChanges,
  onSaveFile,
}: CodeEditorDrawerUnsavedModalProps) => {
  return (
    <DialogModalTemplate
      isOpen={isOpen}
      onCloseDialog={onClose}
      title='You have unsaved changes.'
    >
      <ModalBody>
        <Text>
          What do you want to do regarding changes made to the following file?
        </Text>
        <Text fontWeight='bold' marginTop={4}>
          {filename}
        </Text>
      </ModalBody>
      <ModalFooter paddingTop={4}>
        <HStack justifyContent='space-between' width='100%'>
          <Button onClick={onClose} variant='outline'>
            Keep editing
          </Button>
          <FormActionButtons
            cancelLabel='Discard Changes'
            cancelVariant='danger-ghost'
            isSubmitting={isSubmitting}
            onClickCancel={onResetChanges}
            onClickSubmit={onSaveFile}
            submitLabel='Save Changes'
            submitType='button'
          />
        </HStack>
      </ModalFooter>
    </DialogModalTemplate>
  )
}

export default CodeEditorDrawerUnsavedModal
