import { useCallback, useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import { useField } from 'formik'
import {
  useEditor,
  EditorContent,
  JSONContent,
  EditorEvents,
} from '@tiptap/react'

import { FieldBaseProps } from '@/commons/types'
import './index.css'
import useExtensions from './useExtensions'

export interface MentionItem {
  id: string
  label: string
}

interface FieldMentionTextareaProps extends FieldBaseProps {
  items: MentionItem[]
}

const FieldMentionTextarea = ({
  name,
  items,
  placeholder,
}: FieldMentionTextareaProps) => {
  const [field, meta, helpers] = useField<JSONContent>(name)
  const extensions = useExtensions({ items, name, placeholder })
  const { value } = field

  const handleBlur = useCallback(() => {
    field.onBlur(name)
  }, [field, name])

  const handleChange = useCallback(
    ({ editor }: EditorEvents['update']) => {
      helpers.setValue(editor.getJSON())
    },
    [helpers]
  )

  const handleFocus = useCallback(() => {
    helpers.setTouched(true)
  }, [helpers])

  const editor = useEditor({
    extensions,
    content: value,
    onUpdate: handleChange,
    onBlur: handleBlur,
    onFocus: handleFocus,
  })

  // This effect is used to set the initial value of the editor when the form resets
  useEffect(() => {
    if (value === meta.initialValue) {
      editor?.commands.setContent(value)
    }
  }, [editor, value, meta.initialValue])

  return (
    <Box width='100%' minH='40px'>
      <EditorContent editor={editor} />
    </Box>
  )
}

export default FieldMentionTextarea
