import { useCallback, useEffect, useRef } from 'react'
import { AxiosResponse } from 'axios'
import Editor from '@monaco-editor/react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  chakra,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  HStack,
  Link,
  useDisclosure,
} from '@chakra-ui/react'
import { BiTrash } from 'react-icons/bi'
import { useMutation } from 'react-query'

import ActionButton from '@/components/atoms/ActionButton'
import CodeEditorDrawerDeleteFileModal from '@/components/atoms/CodeEditorDrawerDeleteFileModal'
import CodeEditorDrawerUnsavedModal from '@/components/atoms/CodeEditorDrawerUnsavedModal'
import FormActionButtons from '@/components/atoms/FormActionButtons'
import SideModalHeader from '@/components/atoms/SideModalHeader'
import VantageLoader from '@/components/atoms/VantageLoader'
import { useToasts } from '@/hooks'

type CodeEditorDrawerProps = {
  defaultValue: string | undefined
  error?: any
  filename: string
  isDeleteDisabled?: boolean
  isDownloadingFile?: boolean
  isOpen: boolean
  isReadOnly?: boolean
  language: string
  onClose: () => void
  onDeleteFile: () => Promise<any>
  onSaveFile: (value: string) => Promise<any>
  readOnlyMessage?: string
}

const CodeEditorDrawer = ({
  defaultValue,
  error,
  filename,
  isDeleteDisabled,
  isDownloadingFile,
  isOpen,
  isReadOnly,
  language,
  onClose,
  onDeleteFile,
  onSaveFile,
  readOnlyMessage = 'Cannot edit in read-only editor',
}: CodeEditorDrawerProps) => {
  const editorRefValue = useRef<string>('')
  const {
    renderErrorToast: renderSaveFileErrorToast,
    renderSuccessToast: renderSaveFileSuccessToast,
  } = useToasts({
    errorDescription: 'An error occurred while saving the file.',
    successDescription: 'File saved successfully.',
    errorDescriptionNotAllowed:
      'You may not edit this file because you are not the owner.',
  })
  const {
    renderErrorToast: renderDeleteFileErrorToast,
    renderSuccessToast: renderDeleteFileSuccessToast,
  } = useToasts({
    errorDescription: 'An error occurred while deleting the file.',
    successDescription: 'File deleted successfully.',
    errorDescriptionNotAllowed:
      'You may not delete this file because you are not the owner.',
  })
  const {
    isOpen: isUnsavedChangesModalOpen,
    onClose: onCloseUnsavedChangesModal,
    onOpen: onOpenUnsavedChangesModal,
  } = useDisclosure()
  const {
    isOpen: isDeleteModalOpen,
    onClose: onCloseDeleteModal,
    onOpen: onOpenDeleteModal,
  } = useDisclosure()

  const { mutate: saveFile, isLoading: isSavingFile } = useMutation(
    () => onSaveFile(editorRefValue.current),
    {
      onSuccess: () => {
        renderSaveFileSuccessToast()
        handleReset()
      },
      onError: (error) => {
        const err = error as AxiosResponse
        renderSaveFileErrorToast(err.status)
      },
    }
  )

  const { mutate: deleteFile, isLoading: isDeletingFile } = useMutation(
    onDeleteFile,
    {
      onSuccess: () => {
        renderDeleteFileSuccessToast()
        onCloseDeleteModal()
        handleReset()
      },
      onError: (error) => {
        const err = error as AxiosResponse
        renderDeleteFileErrorToast(err.status)
      },
    }
  )

  const handleChange = useCallback((value: string | undefined) => {
    editorRefValue.current = value ?? ''
  }, [])

  const handleCloseDrawer = () => {
    if (
      !error &&
      !isDownloadingFile &&
      editorRefValue.current !== defaultValue
    ) {
      onOpenUnsavedChangesModal()
    } else {
      onClose()
    }
  }

  const handleReset = () => {
    onCloseUnsavedChangesModal()
    onClose()
    editorRefValue.current = ''
  }

  useEffect(() => {
    if (defaultValue !== undefined) {
      editorRefValue.current = defaultValue
    }
  }, [defaultValue])

  return (
    <>
      <CodeEditorDrawerDeleteFileModal
        filename={filename}
        isOpen={isDeleteModalOpen}
        isSubmitting={isDeletingFile}
        onClose={onCloseDeleteModal}
        onDeleteFile={deleteFile}
      />
      <CodeEditorDrawerUnsavedModal
        filename={filename}
        isOpen={isUnsavedChangesModalOpen}
        onClose={onCloseUnsavedChangesModal}
        isSubmitting={isSavingFile}
        onSaveFile={saveFile}
        onResetChanges={handleReset}
      />
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={handleCloseDrawer}
        size='xl'
      >
        <DrawerOverlay />
        <DrawerContent>
          <SideModalHeader title={filename} onClose={handleCloseDrawer} />
          <DrawerBody paddingTop={2.5} paddingInline={4} overflow='visible'>
            {isDownloadingFile && (
              <Flex
                justify='center'
                alignItems='center'
                height='100%'
                width='100%'
              >
                <VantageLoader />
              </Flex>
            )}
            {!isDownloadingFile && !error && (
              <Box
                border='1px solid'
                borderColor='gray.300'
                borderRadius='8px'
                height='100%'
                overflow='visible'
                padding={1}
                width='100%'
              >
                <Editor
                  defaultValue={defaultValue}
                  language={language}
                  onChange={handleChange}
                  theme='vs-white'
                  options={{
                    readOnly: isReadOnly,
                    readOnlyMessage: {
                      value: readOnlyMessage,
                    },
                  }}
                />
              </Box>
            )}
            {!!error && (
              <Flex justify='center' alignItems='center' height='100%'>
                <Alert
                  alignItems='center'
                  backgroundColor='red.50'
                  borderRadius='6px'
                  lineHeight='24px'
                  maxWidth='400px'
                  status='error'
                  textAlign='left'
                >
                  <AlertIcon boxSize='15px' color='red.500' />
                  <AlertDescription
                    fontSize='sm'
                    fontWeight='normal'
                    verticalAlign='middle'
                  >
                    There was an error downloading{' '}
                    <chakra.span fontWeight='semibold'>{filename}.</chakra.span>
                    <chakra.br />
                    Please try again. If the problem persists, please{' '}
                    <Link href='/support' color='red.600' fontWeight='semibold'>
                      create a support ticket
                    </Link>
                    .
                  </AlertDescription>
                </Alert>
              </Flex>
            )}
          </DrawerBody>
          <DrawerFooter paddingInline={4}>
            <HStack justifyContent='space-between' width='100%'>
              <ActionButton
                isDisabled={
                  isDeleteDisabled || isDownloadingFile || !!error || isReadOnly
                }
                leftIcon={<BiTrash />}
                onClick={onOpenDeleteModal}
                tooltipProps={{
                  label: readOnlyMessage,
                }}
                variant='danger-outline'
              >
                Delete File
              </ActionButton>
              <FormActionButtons
                cancelLabel='Cancel'
                isDisabled={isDownloadingFile || !!error || isReadOnly}
                isSubmitting={isSavingFile}
                onClickCancel={handleCloseDrawer}
                onClickSubmit={saveFile}
                submitLabel='Save'
                submitType='button'
                tooltipProps={{
                  label: readOnlyMessage,
                }}
              />
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default CodeEditorDrawer
