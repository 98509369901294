const Popover = {
  parts: [
    'content',
    'header',
    'body',
    'footer',
    'popper',
    'arrow',
    'closeButton',
  ],
  baseStyle: {
    popper: {
      zIndex: 'popover',
    },
  },
  sizes: {},
  defaultProps: {},
  variants: {
    solid: {
      content: {
        _focus: { boxShadow: 'none' },
        backgroundColor: 'white',
        boxShadow: 'md',
        borderColor: 'gray.300',
        borderWidth: '1px',
      },
      body: {
        paddingInlineStart: 0,
        paddingInlineEnd: 0,
        paddingTop: 0,
        borderRadius: 'md',
      },
    },
    info: {
      arrow: {
        // russianViolet.600
        background: '#0E0831 !important',
      },
      body: {
        fontSize: 'xs',
        borderRadius: '8px',
        color: 'white',
      },
      content: {
        backgroundColor: 'russianViolet.600',
        borderWidth: 0,
      },
    },
  },
}

export default Popover
