import { HelpTextPopover } from '@/components/atoms'
import { Badge, BadgeProps, HStack, Text, TextProps } from '@chakra-ui/react'

type DataBadgeFieldProps = {
  colorScheme?: string
  label?: string
  labelTextProps?: TextProps
  value?: string
  valueBadgeProps?: BadgeProps
  tooltipMessage?: string | React.ReactNode
}

const DataBadgeField = ({
  colorScheme,
  label,
  labelTextProps,
  value,
  valueBadgeProps,
  tooltipMessage,
}: DataBadgeFieldProps) => {
  if (!value) return null
  return (
    <HStack spacing={2}>
      {!!label && (
        <HStack spacing={1} width='fit-content'>
          <HelpTextPopover tooltipMessage={tooltipMessage} />
          <Text
            fontSize='sm'
            color='black'
            fontWeight='semibold'
            whiteSpace='nowrap'
            {...labelTextProps}
          >
            {label}:
          </Text>
        </HStack>
      )}
      <Badge
        borderRadius='4px'
        colorScheme={colorScheme}
        variant='solid'
        {...valueBadgeProps}
      >
        {value}
      </Badge>
    </HStack>
  )
}

export default DataBadgeField
