import { useCallback, useEffect, useState } from 'react'

import { setOrganizationDetails } from '@/store/guardianSlice'
import { Axios } from '@/commons/helpers'

import useAppSelector from './useAppSelector'
import useAppDispatch from './useAppDispatch'
import useKeycloak from './useKeycloak'

const useAppInit = () => {
  // hooks
  const dispatch = useAppDispatch()
  const orgDetails = useAppSelector(
    (state) => state.guardian.organizationDetails
  )
  const [isAppInitializing, setIsAppInitializing] = useState(true)
  const { hasOrganizationInToken, isAuthenticated } = useKeycloak()

  const getAdditionalInformation = useCallback(async () => {
    const organizationInfo = await Axios.get(
      '/admin/management/organizations/my'
    )
      .then(({ data }) => data)
      .catch((error) => {
        console.error(error)
      })

    if (organizationInfo) {
      dispatch(setOrganizationDetails(organizationInfo))
    }
  }, [dispatch])

  // fetch additional data, such as company info, etc.
  // and triggers the app to render the first time
  useEffect(() => {
    if (!isAuthenticated) return
    if (hasOrganizationInToken) getAdditionalInformation()
    else setIsAppInitializing(false)
  }, [
    dispatch,
    getAdditionalInformation,
    hasOrganizationInToken,
    isAuthenticated,
  ])

  useEffect(() => {
    if (orgDetails) {
      setIsAppInitializing(false)
    }
  }, [orgDetails])

  return {
    isAppInitializing,
  }
}

export default useAppInit
