import { useRef } from 'react'
import {
  Flex,
  Img,
  Text,
  useDimensions,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import Forbidden from '@/assets/images/Forbidden.png'

const ForbiddenPage = () => {
  const elementRef = useRef(null)
  const elementDimensions = useDimensions(elementRef)
  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      width='100%'
      height='80vh'
    >
      <Wrap justify='center' spacingX='2rem' spacingY='2.5rem'>
        <WrapItem width={elementDimensions?.borderBox?.width}>
          <Flex width='100%' justifyContent='center'>
            <Img src={Forbidden} />
          </Flex>
        </WrapItem>
        <WrapItem ref={elementRef} alignItems='center'>
          <VStack alignItems='flex-start' spacing={3}>
            <Text
              color='russianViolet.500'
              fontWeight='bold'
              fontSize='7.5rem'
              lineHeight='7.5rem'
            >
              403
            </Text>
            <VStack fontWeight='semibold' spacing={2} alignItems='flex-start'>
              <Text color='gray.500' fontSize='md'>
                Halt! You do not have permission to view this page.
              </Text>
              <Text color='gray.500' fontSize='md' maxWidth='370px'>
                If you believe you should have access, please reach out to your
                organization's administrator and ask them to provide you with
                the necessary permissions.
              </Text>
            </VStack>
          </VStack>
        </WrapItem>
      </Wrap>
    </Flex>
  )
}

export default ForbiddenPage
