const checkForNecessaryPermissions = (
  userPermissions: string[],
  requiredPermissions?: string[]
) => {
  if (!requiredPermissions) return true
  return requiredPermissions.every((permission) =>
    userPermissions.includes(permission)
  )
}

export default checkForNecessaryPermissions
