import {
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from 'oidc-client-ts'
import { keycloakClient, keycloakUrl } from './LocalEnvData'

const userConfig: UserManagerSettings = {
  authority: keycloakUrl,
  client_id: keycloakClient,
  redirect_uri: window.location.href,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
}

const UserManagerClient = new UserManager(userConfig)

export default UserManagerClient
