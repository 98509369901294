import {
  chakra,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
} from '@chakra-ui/react'
import { BiDotsHorizontal } from 'react-icons/bi'
import { TableActionsProps } from '@/commons/interfaces'
import { ActionTableIconButton } from '@/components/atoms'

const TableActions = ({ actions }: TableActionsProps) => {
  return (
    <chakra.div
      // prevents that the event reaches to tr onClick function
      onClick={(event) => {
        event.stopPropagation()
      }}
      display='flex'
      justifyContent='flex-end'
    >
      {!Array.isArray(actions) && (
        <ActionTableIconButton {...actions} type='single' />
      )}
      {Array.isArray(actions) && (
        <Menu autoSelect={false}>
          <MenuButton
            as={IconButton}
            aria-label='Options'
            icon={<Icon as={BiDotsHorizontal} color='tableEllipsis' />}
            variant='ghost'
            height='25px'
            minWidth='25px'
          />
          <MenuList boxShadow='md' borderRadius='8px'>
            {actions
              .filter(({ isHidden }) => !isHidden)
              .map((action, index) => (
                <ActionTableIconButton key={index} {...action} type='menu' />
              ))}
          </MenuList>
        </Menu>
      )}
    </chakra.div>
  )
}

export default TableActions
