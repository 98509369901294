import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  chakra,
  HStack,
  Icon,
} from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import NavItem from '@/components/atoms/NavItem'
import RootItem from '@/components/atoms/RootItem'
import getColorWithOpacity from '@/commons/helpers/getColorWithOpacity'
import { useKeycloak } from '@/hooks'
import { checkForNecessaryPermissions } from '@/commons/helpers'

interface SectionItemProps {
  dataTour?: string
  externalUrl?: string
  isSidebarOpen: boolean
  linkItems?: {
    name: string
    requiredPermissions?: string[]
    route: string
    dataTour?: string
  }[]
  name: string
  rootUrl?: string
  route?: string
  SectionIcon: IconType
}

const SectionItem = ({
  dataTour,
  externalUrl,
  history,
  isSidebarOpen,
  linkItems,
  location,
  match,
  name,
  rootUrl,
  route,
  SectionIcon,
}: SectionItemProps & RouteComponentProps) => {
  const { userPermissions } = useKeycloak()

  if (route)
    return (
      <RootItem
        dataTour={dataTour}
        history={history}
        isSidebarOpen={isSidebarOpen}
        location={location}
        match={match}
        name={name}
        route={route}
        SectionIcon={SectionIcon}
      />
    )

  if (externalUrl) {
    return (
      <RootItem
        dataTour={dataTour}
        externalUrl={externalUrl}
        history={history}
        isSidebarOpen={isSidebarOpen}
        location={location}
        match={match}
        name={name}
        SectionIcon={SectionIcon}
      />
    )
  }

  const pathname = location.pathname
  const isActive = pathname.startsWith(rootUrl || '')

  return (
    <AccordionItem>
      {({ isExpanded }: { isExpanded: boolean }) => (
        <>
          <AccordionButton
            _focus={{ outline: 'none' }}
            _hover={{
              backgroundColor: getColorWithOpacity('iris.800', 0.5),
            }}
            data-tour={dataTour}
            paddingInlineStart={3}
            backgroundColor={isExpanded ? 'iris.800' : 'navigationBackground'}
            borderRadius='8px'
            minWidth='224px'
          >
            <HStack width='100%' spacing={3}>
              <Icon
                as={SectionIcon}
                boxSize={'18px'}
                color={
                  isActive
                    ? 'navigationAccordionIcon'
                    : isExpanded
                    ? 'navigationAccordionIcon'
                    : 'fluorescentBlue.500'
                }
              />
              <chakra.span
                fontWeight={
                  isActive ? 'semibold' : isExpanded ? 'semibold' : 'normal'
                }
                fontSize='sm'
                transition='opacity 0.3s ease'
                opacity={isSidebarOpen ? 1 : 0}
                lineHeight={5}
              >
                {name}
              </chakra.span>
            </HStack>
            <AccordionIcon
              color='navigationAccordionIcon'
              transform={
                // unset prevents the default rotate(-180deg) from being applied
                isExpanded ? 'unset' : 'rotate(-90deg)'
              }
              boxSize='18px'
            />
          </AccordionButton>
          <AccordionPanel pb={4}>
            {!!linkItems &&
              linkItems
                .filter((item) => {
                  return checkForNecessaryPermissions(
                    userPermissions,
                    item.requiredPermissions
                  )
                })
                .map((link) => (
                  <NavItem
                    key={link.name}
                    route={link.route}
                    location={location}
                    history={history}
                    match={match}
                  >
                    {link.name}
                  </NavItem>
                ))}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}

export default withRouter(SectionItem)
