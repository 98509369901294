import React, { useState } from 'react'
import Select, { GroupBase } from 'react-select'
import { SelectComponents } from 'react-select/dist/declarations/src/components'
import { get } from 'lodash'
import { FormControl, FormErrorMessage, HStack } from '@chakra-ui/react'
import { Field, FieldProps as FormikFieldProps, FormikProps } from 'formik'

import { FieldBaseProps, SelectOption } from '@/commons/types'

import makeStyles from './makeStyles'
import DropdownIndicator from './components/DropdownIndicator'
import OptionComponent from './components/Option'
import { FieldLabel } from '@/components/atoms'

interface FieldSelectProps extends FieldBaseProps {
  components?:
    | Partial<SelectComponents<any, boolean, GroupBase<any>>>
    | undefined
  isLoading?: boolean
  isMulti?: boolean
  LeftAddon?: React.ReactNode
  noOptionsMessage?: (obj: { inputValue: string }) => React.ReactNode
  options: SelectOption[] | undefined
  onChange?: (
    value: SelectOption | SelectOption[],
    form: FormikProps<any>
  ) => void
}

const getValue = (
  fieldValue: string | string[],
  isMulti: boolean | undefined,
  options: SelectOption[] | undefined
) => {
  if (!fieldValue || !options) return null
  if (isMulti) return fieldValue
  return (
    options.find((option) => String(option.value) === String(fieldValue)) ??
    null
  )
}

const FieldSelect = ({
  components,
  externalError,
  hideErrorMessage,
  isDisabled,
  isLoading,
  isMulti,
  isRequired,
  label,
  LabelComponent,
  labelProps,
  LeftAddon,
  name,
  noOptionsMessage,
  onChange,
  options,
  placeholder,
  subLabel,
  tooltipMessage,
}: FieldSelectProps) => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <Field name={name}>
      {({ field, form }: FormikFieldProps<string | string[]>) => {
        const errorMessage = get(form, `errors.${name}`) || externalError,
          hasError = !!(errorMessage && get(form, `touched.${name}`))

        const fieldValue = getValue(field.value, isMulti, options)

        const handleChange = (
          selectedOptions: SelectOption | SelectOption[]
        ): void => {
          if (onChange) {
            onChange(selectedOptions, form)
            return
          }
          if (isMulti) {
            form.setFieldValue(name, selectedOptions)
          } else {
            form.setFieldValue(name, (selectedOptions as SelectOption).value)
          }
        }

        const handleBlur = (): void => {
          form.setFieldTouched(name)
          setIsFocused(false)
        }
        const handleFocus = () => setIsFocused(true)

        return (
          <FormControl isRequired={isRequired} isInvalid={hasError}>
            {label && (
              <FieldLabel
                hasError={hasError}
                label={label}
                LabelComponent={LabelComponent}
                labelProps={labelProps}
                name={name}
                subLabel={subLabel}
                tooltipMessage={tooltipMessage}
              />
            )}

            <HStack
              _hover={{
                borderColor: isFocused || isDisabled ? 'none' : 'gray.300',
              }}
              borderColor={
                isFocused
                  ? 'iris.500'
                  : !isDisabled && hasError
                  ? 'red.500'
                  : 'gray.200'
              }
              borderWidth='1px'
              borderRadius='8px'
              cursor={isDisabled ? 'not-allowed' : 'pointer'}
              spacing={0}
              width='100%'
            >
              {LeftAddon}
              <Select
                components={{
                  DropdownIndicator,
                  Option: OptionComponent,
                  ...components,
                }}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isMulti={isMulti}
                menuPlacement='auto'
                menuPortalTarget={document.body}
                minMenuHeight={300}
                name={name}
                noOptionsMessage={noOptionsMessage}
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                options={options}
                placeholder={placeholder}
                styles={makeStyles()}
                value={fieldValue}
              />
            </HStack>

            {!hideErrorMessage && (
              <FormErrorMessage fontSize='xs' fontWeight='normal' marginTop={1}>
                {errorMessage}
              </FormErrorMessage>
            )}
          </FormControl>
        )
      }}
    </Field>
  )
}

export default FieldSelect
