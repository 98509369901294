import {
  RouteProps,
  RouteComponentProps,
  useLocation,
  Redirect,
  Route,
} from 'react-router-dom'

import routePermissions from '@/commons/constants/routePermission'
import { checkForNecessaryPermissions } from '@/commons/helpers'
import { usePageTracking, useKeycloak } from '@/hooks'

interface PrivateRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
  exact?: boolean
  path: string
}

const PrivateRoute = ({ component, path, ...args }: PrivateRouteProps) => {
  usePageTracking()
  const { userPermissions } = useKeycloak()
  const { pathname } = useLocation()

  const restrictedRoutes = Object.keys(routePermissions)
  const currentRestrictedRoute = restrictedRoutes.find((route) =>
    pathname.startsWith(route)
  )

  if (currentRestrictedRoute) {
    const hasNecessaryPermissions = checkForNecessaryPermissions(
      userPermissions,
      routePermissions[currentRestrictedRoute]
    )

    if (!hasNecessaryPermissions) {
      return <Redirect to='/forbidden' />
    }
  }

  return <Route component={component} path={path} {...args} />
}

export default PrivateRoute
