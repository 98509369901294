import { Box, Icon, IconButton, MenuItem, Tooltip } from '@chakra-ui/react'
import { get } from 'lodash'

import { checkForNecessaryPermissions } from '@/commons/helpers'
import { MenuRowAction, SingleRowAction } from '@/commons/interfaces'
import { useKeycloak } from '@/hooks'

type ActionTableIconButtonProps =
  | ({
      type: 'single'
    } & SingleRowAction)
  | ({
      type: 'menu'
    } & MenuRowAction)

const getTooltipLabel = (
  hasNecessaryPermissions: boolean,
  tooltipLabel: string,
  isDisabled?: boolean,
  tooltipDisabledMessage?: string
) => {
  if (!hasNecessaryPermissions) {
    return "You don't have permission to perform this action."
  }
  if (isDisabled) {
    return tooltipDisabledMessage ?? ''
  }
  return tooltipLabel
}

const ActionTableIconButton = ({
  color,
  icon,
  isDisabled,
  onClick,
  requiredPermissions,
  tooltipDisabledMessage,
  ...action
}: ActionTableIconButtonProps) => {
  const { userPermissions } = useKeycloak()

  const hasNecessaryPermissions = checkForNecessaryPermissions(
    userPermissions,
    requiredPermissions
  )
  const isButtonDisabled = isDisabled ? true : !hasNecessaryPermissions
  const currentTooltipLabel = getTooltipLabel(
    hasNecessaryPermissions,
    get(action, 'tooltipLabel', ''),
    isDisabled,
    tooltipDisabledMessage
  )

  if (action.type === 'single') {
    const { ariaLabel } = action
    return (
      <Tooltip
        label={currentTooltipLabel}
        hasArrow
        placement='top-end'
        gutter={0}
        padding={2}
        lineHeight='16px'
      >
        <Box>
          <IconButton
            _active={{
              backgroundColor: 'transparent',
            }}
            _hover={{
              backgroundColor: 'transparent',
            }}
            _disabled={{
              backgroundColor: 'transparent',
              opacity: 0.5,
            }}
            aria-label={ariaLabel}
            backgroundColor='transparent'
            color={color}
            cursor={isDisabled ? 'not-allowed' : 'pointer'}
            icon={<Icon as={icon} boxSize='16px' />}
            isDisabled={isButtonDisabled}
            onClick={onClick}
            size='sm'
          />
        </Box>
      </Tooltip>
    )
  }

  return (
    <Tooltip
      gutter={0}
      hasArrow
      isDisabled={!isButtonDisabled}
      label={currentTooltipLabel}
      lineHeight='16px'
      padding={2}
      placement='top-end'
    >
      <Box>
        <MenuItem
          color={color}
          icon={
            <Icon
              as={icon}
              boxSize={4}
              verticalAlign='middle'
              marginTop='-4px'
            />
          }
          fontSize='sm'
          height='fit-content'
          isDisabled={isButtonDisabled}
          onClick={onClick}
        >
          {action.label}
        </MenuItem>
      </Box>
    </Tooltip>
  )
}

export default ActionTableIconButton
