import React, { useCallback } from 'react'
import { TableDialogContent } from '@/commons/interfaces/components/Table'

type DialogContainerProps<TableData extends object> = {
  dialogContent: TableDialogContent<TableData> | null
  onCloseDialog: () => void
  refetch?: () => void
}

const DialogContainer = <TableData extends object>({
  dialogContent,
  onCloseDialog,
  refetch,
}: DialogContainerProps<TableData>) => {
  // workaround to handle when the table doesn't pass the refetch function
  const handleRefetch = useCallback(() => {
    if (refetch) refetch()
  }, [refetch])
  if (!dialogContent) return <></>
  const { dialogComponent, content } = dialogContent
  return React.createElement(dialogComponent, {
    content,
    context: 'list',
    handleCloseDialog: onCloseDialog,
    refetch: handleRefetch,
  })
}

export default DialogContainer
