import { chakra, ModalBody, ModalFooter, Text } from '@chakra-ui/react'

import FormActionButtons from '@/components/atoms/FormActionButtons'
import DialogModalTemplate from '@/components/atoms/DialogModalTemplate'

type CodeEditorDrawerDeleteFileModalProps = {
  filename: string
  isOpen: boolean
  isSubmitting: boolean
  onClose: () => void
  onDeleteFile: () => void
}

const CodeEditorDrawerDeleteFileModal = ({
  filename,
  isOpen,
  isSubmitting,
  onClose,
  onDeleteFile,
}: CodeEditorDrawerDeleteFileModalProps) => {
  return (
    <DialogModalTemplate
      isOpen={isOpen}
      onCloseDialog={onClose}
      title='Delete File'
    >
      <ModalBody>
        <Text>
          What do you want to do{' '}
          <chakra.span fontWeight='semibold'>delete</chakra.span> the following
          file?
        </Text>
        <Text fontWeight='bold' marginTop={4}>
          {filename}
        </Text>
      </ModalBody>
      <ModalFooter>
        <FormActionButtons
          isSubmitting={isSubmitting}
          onClickCancel={onClose}
          onClickSubmit={onDeleteFile}
          submitLabel='Delete'
          submitType='button'
          submitVariant='danger'
        />
      </ModalFooter>
    </DialogModalTemplate>
  )
}

export default CodeEditorDrawerDeleteFileModal
