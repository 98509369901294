import {
  chakra,
  Flex,
  HStack,
  Icon,
  Tag,
  TagLabel,
  TagLabelProps,
  Text,
  TextProps,
  Tooltip,
} from '@chakra-ui/react'
import { BiInfoCircle } from 'react-icons/bi'

import { HelpTextPopover } from '@/components/atoms'
import { tagStylesByVariant } from './tagStyles'

type TagVariants = keyof typeof tagStylesByVariant

type StatusTagProps = {
  label?: string
  labelTextProps?: TextProps
  labelTooltipMessage?: string | React.ReactNode
  tooltipMessage?: string
  value?: string
  valueTagLabelProps?: TagLabelProps
  variant?: TagVariants
}

// fallback case for non-mapped values returned by the api
const FALLBACK_VARIANT = 'teal'

const DataStatusTag = ({
  label,
  labelTextProps,
  labelTooltipMessage,
  tooltipMessage,
  value,
  valueTagLabelProps,
  variant,
}: StatusTagProps) => {
  const { backgroundColor, valueColor } =
    tagStylesByVariant[variant ?? FALLBACK_VARIANT]
  return (
    <HStack spacing={2} justifyContent='flex-start'>
      {!!label && (
        <HStack spacing={1} width='fit-content'>
          <HelpTextPopover tooltipMessage={labelTooltipMessage} />
          <Text
            fontSize='sm'
            color='black'
            fontWeight='semibold'
            whiteSpace='nowrap'
            {...labelTextProps}
          >
            {label}:
          </Text>
        </HStack>
      )}
      <Tag size='sm' borderRadius='full' backgroundColor={backgroundColor}>
        <TagLabel
          color={valueColor}
          fontWeight='semibold'
          fontSize='xs'
          alignItems='center'
          {...valueTagLabelProps}
        >
          <HStack
            spacing={1}
            alignItems='center'
            height={4}
            lineHeight={4}
            marginTop='1px'
          >
            <chakra.span
              verticalAlign='middle'
              display='inline-block'
              lineHeight='normal'
            >
              {value}
            </chakra.span>
            {!!tooltipMessage && (
              <Tooltip
                fontSize='xs'
                hasArrow
                label={tooltipMessage}
                offset={[10, 14]}
                padding={2}
                placement='top-end'
              >
                <Flex>
                  <Icon as={BiInfoCircle} boxSize='14px' color={valueColor} />
                </Flex>
              </Tooltip>
            )}
          </HStack>
        </TagLabel>
      </Tag>
    </HStack>
  )
}

export default DataStatusTag
