import {
  WatcherResponse,
  AlertResponse,
  ActionsResponse,
} from '@/commons/interfaces'

export type ExtendedAlert = AlertResponse & { actionType: 'alert' }

const getPromiseSettledValue = <T>(
  promiseResult: PromiseSettledResult<T | undefined>
) => {
  if (promiseResult.status === 'fulfilled' && !!promiseResult.value) {
    return promiseResult.value
  } else {
    return []
  }
}

const makeAlertsFromWatchers = (watchers: WatcherResponse[]) =>
  watchers
    ?.reduce<AlertResponse[]>((acc, curr) => acc.concat(curr.alerts), [])
    .map((alert) => ({ actionType: 'alert' as const, ...alert }))

const sortNotifications = (
  notifications: (ExtendedAlert | ActionsResponse)[]
) =>
  notifications.sort((a, b) => {
    const dateA = new Date(a.createdAt!)
    const dateB = new Date(b.createdAt!)
    return dateB.getTime() - dateA.getTime()
  })

export { getPromiseSettledValue, makeAlertsFromWatchers, sortNotifications }
