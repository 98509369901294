import { ChangeEvent, useEffect } from 'react'
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputProps,
} from '@chakra-ui/react'
import { FieldInputProps as FormikFieldInputProps, FormikProps } from 'formik'
import { FieldBaseProps } from '@/commons/types'
import getColorWithOpacity from '@/commons/helpers/getColorWithOpacity'

export interface InnerInputProps extends FieldBaseProps {
  field: FormikFieldInputProps<any>
  focusOnMount?: boolean
  form: FormikProps<any>
  hasError: boolean
  innerRef: React.RefObject<HTMLInputElement>
  isValid?: boolean
  LeftElementComponent?: React.ReactNode
  name: string
  onChange: (event: ChangeEvent<any>) => void
  placeholder?: string
  RightElementComponent?: React.ReactNode
  type: string
}

const makeInputStyles = (isValid?: boolean) => {
  if (isValid) {
    return {
      color: getColorWithOpacity('green.600', 1, true),
      borderColor: getColorWithOpacity('green.500', 1, true),
    }
  } else {
    return {
      color: 'gray.700',
      borderColor: 'gray.200',
    }
  }
}

const InnerInput = ({
  field,
  focusOnMount,
  hasError,
  innerRef,
  isDisabled,
  isRequired,
  isValid,
  LeftElementComponent,
  name,
  onChange,
  placeholder,
  RightElementComponent,
  type,
  ...props
}: InnerInputProps & InputProps) => {
  useEffect(() => {
    if (focusOnMount) {
      innerRef?.current?.focus()
    }
  }, [innerRef, focusOnMount])

  return (
    <InputGroup>
      {!!LeftElementComponent && (
        <InputLeftElement children={LeftElementComponent} />
      )}
      <Input
        {...field}
        id={name}
        isDisabled={isDisabled}
        isRequired={isRequired}
        onChange={onChange}
        placeholder={placeholder}
        ref={innerRef}
        type={type}
        {...makeInputStyles(isValid)}
        {...props}
      />
      {!!RightElementComponent && (
        <InputRightElement children={RightElementComponent} />
      )}
    </InputGroup>
  )
}

export default InnerInput
