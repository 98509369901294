import { ActionComponent } from '@/components/molecules/DataActionField'
import { Tooltip, IconButton } from '@chakra-ui/react'

const ActionFieldComponent = ({
  ariaLabel,
  icon,
  isDisabled,
  isHidden,
  isOpen,
  onClick,
  tooltipLabel,
}: ActionComponent) => {
  if (isHidden) return <></>
  return (
    <Tooltip
      hasArrow
      label={tooltipLabel}
      isOpen={isOpen}
      placement='top-start'
      offset={[-5, 5]}
    >
      <IconButton
        aria-label={ariaLabel}
        icon={icon}
        isDisabled={isDisabled}
        onClick={onClick}
        variant='icon-button-ghost'
      />
    </Tooltip>
  )
}

export default ActionFieldComponent
