import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const useScrollToElement = () => {
  const { hash } = useLocation()
  const hashRef = useRef(hash)
  const scrolledRef = useRef(false)

  useEffect(() => {
    if (hash) {
      if (hashRef.current !== hash) {
        hashRef.current = hash
        scrolledRef.current = false
      }

      if (!scrolledRef.current) {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          // block: 'center' is used to center the element in the screen
          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
          scrolledRef.current = true
        }
      }
    }
  })
}

export default useScrollToElement
