import { BiInfoCircle } from 'react-icons/bi'
import {
  Box,
  HStack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  TextProps,
  Tooltip,
} from '@chakra-ui/react'
import HelpTextPopover from '../HelpTextPopover'

interface ClusterDataFieldProps {
  clientId: string
  label?: string
  labelTextProps?: TextProps
  tooltipMessage?: string | React.ReactNode
}

const UnavailableClusterTag = ({
  clientId,
  label,
  labelTextProps,
  tooltipMessage,
}: ClusterDataFieldProps) => (
  <HStack spacing={2}>
    {!!label && (
      <HStack spacing={1} width='fit-content'>
        <HelpTextPopover tooltipMessage={tooltipMessage} />
        <Text
          fontSize='sm'
          color='black'
          fontWeight='semibold'
          whiteSpace='nowrap'
          {...labelTextProps}
        >
          {label}:
        </Text>
      </HStack>
    )}
    <Tag size='sm' colorScheme='red' borderRadius='full'>
      <Tooltip
        padding={2}
        offset={[10, 10]}
        placement='top-end'
        hasArrow
        label={`The cluster, ${clientId}, is not available. This configuration will have no effect. Please remove this configuration or select another cluster for it to target.`}
      >
        <Box boxSize='14px' cursor='pointer'>
          <TagLeftIcon
            as={BiInfoCircle}
            boxSize='14px'
            color='red.600'
            margin={0}
          />
        </Box>
      </Tooltip>
      <TagLabel
        // styling to make sure the text is vertically centered
        color='red.600'
        fontWeight='semibold'
        height={5}
        lineHeight={4}
        marginLeft={1}
        paddingTop='2px'
      >
        {clientId}
      </TagLabel>
    </Tag>
  </HStack>
)

export default UnavailableClusterTag
