import { Attachments } from '../interfaces'

const convertAttachmentToFile = (attachment: Attachments) => {
  const { filename, size, type } = attachment
  const arrayBuffer = new ArrayBuffer(size)
  const file = new File([arrayBuffer], filename, { type })
  return file
}

export default convertAttachmentToFile
