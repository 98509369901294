import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query'
import { AxiosRequestHeaders } from 'axios'
import { Axios } from '.'

interface MutationReturnFields {
  [key: string]: boolean | MutationReturnFields
}

interface UploadArgs {
  [key: string]:
    | VariableType
    | string
    | string[]
    | number
    | number[]
    | UploadArgs
}

interface UploadFragment {
  __typeName: string
  fields: MutationReturnFields
}

type UploadMutationServiceProps = {
  files: File[]
  operationName: string
  route: string
  uploadArgs: UploadArgs
  uploadFragments: UploadFragment[]
  uploadHeaders?: AxiosRequestHeaders
  uploadMutationName: string
  uploadValues?: Record<string, unknown>
  uploadVariables?: Record<string, string>
}

/* 
    This function follows the official graphql multipart request spec:
    https://github.com/jaydenseric/graphql-multipart-request-spec#graphql-multipart-request-specification
*/

const makeFormData = ({
  files,
  operationName = 'UploadFiles',
  uploadArgs,
  uploadFragments,
  uploadMutationName,
  uploadValues = {},
  uploadVariables,
}: Omit<UploadMutationServiceProps, 'uploadHeaders' | 'route'>) => {
  let formData = new FormData()

  const query = jsonToGraphQLQuery({
    mutation: {
      __name: operationName,
      __variables: { ...uploadVariables, files: '[Upload!]!' },
      [uploadMutationName]: {
        __args: {
          ...uploadArgs,
          attachments: new VariableType('files'),
        },
        __on: uploadFragments.map(({ __typeName, fields }) => ({
          __typeName,
          ...fields,
        })),
      },
    },
  })

  const operations = JSON.stringify({
    query,
    variables: { ...uploadValues, files: Array(files.length).fill(null) },
  })

  const map = JSON.stringify(
    files.reduce((acc, file, index) => {
      acc[`file${index + 1}`] = [`variables.files.${index}`]
      return acc
    }, {} as Record<string, string[]>)
  )

  formData.append('operations', operations)
  formData.append('map', map)
  files.forEach((file, index) => {
    formData.append(`file${index + 1}`, file)
  })
  return formData
}

const uploadMutationService = <ReturnType extends Record<string, unknown>>({
  route,
  uploadHeaders,
  ...uploadData
}: UploadMutationServiceProps) =>
  Axios.post<ReturnType>(route, makeFormData(uploadData), {
    headers: {
      ...uploadHeaders,
      'Content-Type': 'multipart/form-data',
    },
  })

export default uploadMutationService
