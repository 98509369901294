import { JSONContent } from '@tiptap/react'
import { uniq } from 'lodash'

const extractMentionsFromTipTapObject = (
  tipTapObject: JSONContent[],
  mentions: string[] = []
) => {
  tipTapObject.forEach((node) => {
    if (node.type === 'mention') {
      mentions.push(node?.attrs?.id)
    }
    if (node.content) {
      mentions = mentions.concat(
        // Recursively call the function to handle nested paragraphs
        extractMentionsFromTipTapObject(node.content, mentions)
      )
    }
  })

  return uniq(mentions)
}

export default extractMentionsFromTipTapObject
