const hexToRgb = (
  hex: string,
  opacity: number = 1,
  addImportant: boolean = false
) => {
  // Remove the "#" symbol from the start of the hex string, if it exists
  hex = hex.replace(/^#/, '')

  // Parse the hex string into its RGB components
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  const rgbaString = `rgba(${r}, ${g}, ${b}, ${opacity})`
  if (addImportant) {
    return `${rgbaString} !important`
  }

  // Return the RGB color string
  return rgbaString
}

export default hexToRgb
