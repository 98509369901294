import {
  HStack,
  IconButton,
  Stack,
  Text,
  TextProps,
  Tooltip,
  Icon,
  Box,
} from '@chakra-ui/react'
import { BiCopy } from 'react-icons/bi'

import HelpTextPopover from '@/components/atoms/HelpTextPopover'
import { useChakraClipboard } from '@/hooks'

export type TextFieldProps = {
  hasCopyButton?: boolean
  isParagraph?: boolean
  label?: string
  labelTextProps?: TextProps
  tooltipMessage?: string | React.ReactNode
  value?: string | number
  valueTextProps?: TextProps
}

type OverflowStyling = Partial<
  Pick<TextProps, 'textOverflow' | 'whiteSpace' | 'overflow'>
>

const valueOverflowStyling = (isParagraph?: boolean): OverflowStyling =>
  isParagraph
    ? { whiteSpace: 'unset' }
    : {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }

const TextFieldComponent = ({
  hasCopyButton,
  isParagraph,
  label,
  labelTextProps,
  tooltipMessage,
  value,
  valueTextProps,
}: TextFieldProps) => {
  const { onCopy, isTooltipOpen } = useChakraClipboard(value?.toString() ?? '')
  const tooltipLabel = isTooltipOpen
    ? `${label} copied to clipboard!`
    : `Copy ${label} to clipboard`

  return (
    <Stack
      spacing={isParagraph ? 1 : 2}
      direction={isParagraph ? 'column' : 'row'}
      lineHeight={5}
      width='100%'
    >
      {!!label && (
        <HStack spacing={1} width='fit-content'>
          <HelpTextPopover tooltipMessage={tooltipMessage} />
          <Text
            fontSize='sm'
            color='black'
            fontWeight='semibold'
            whiteSpace='nowrap'
            {...labelTextProps}
          >
            {label}:
          </Text>
        </HStack>
      )}
      <HStack spacing={2} overflow='hidden'>
        <Text
          color='gray.600'
          fontSize='sm'
          {...valueOverflowStyling(isParagraph)}
          {...valueTextProps}
        >
          {value}
        </Text>
        {hasCopyButton && value !== '-' && (
          <Tooltip
            hasArrow
            label={tooltipLabel}
            isOpen={isTooltipOpen}
            placement='top-start'
            offset={[-5, 5]}
          >
            <Box boxSize='fit-content'>
              <IconButton
                _focus={{
                  boxShadow: 'none',
                }}
                aria-label='copy to clipboard'
                icon={<Icon as={BiCopy} />}
                onClick={onCopy}
                variant='icon-button-ghost'
              />
            </Box>
          </Tooltip>
        )}
      </HStack>
    </Stack>
  )
}

export default TextFieldComponent
