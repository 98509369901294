import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { IconType } from 'react-icons'
import { useMediaQuery } from '@chakra-ui/react'
import { intersection } from 'lodash'

import { useAppDispatch, useAppSelector, useHover, useKeycloak } from '@/hooks'
import {
  selectSidebarIsOpen,
  setSidebarClosed,
  setSidebarOpen,
  toggleSidebar,
} from '@/store/sidebarSlice'

type SectionItemProps = {
  externalUrl?: string
  linkItems?: { name: string; route: string; dataTour?: string }[]
  name: string
  requiredPermissions?: string[]
  rootUrl?: string
  route?: string
  SectionIcon: IconType
}

const useSidebarHelper = (sectionItems: SectionItemProps[]) => {
  const { userPermissions } = useKeycloak()
  const [hoverRef, isHovered] = useHover<HTMLDivElement>(50)
  const location = useLocation()
  const dispatch = useAppDispatch()
  const [isLargerThan980] = useMediaQuery('(min-width: 980px)')

  const isFixedOpen = useAppSelector(selectSidebarIsOpen)
  const [openAccordionItems, setOpenAccordionItems] = useState<number>()
  const [isForceClosed, setIsForceClosed] = useState(false)

  const isSidebarOpen = isFixedOpen || (isHovered && !isForceClosed)

  const onChangeAccordionItem = useCallback(
    (openIndexes) => {
      setOpenAccordionItems(openIndexes)
    },
    [setOpenAccordionItems]
  )

  const onToggleSidebarState = useCallback(() => {
    dispatch(toggleSidebar())
  }, [dispatch])

  useEffect(() => {
    if (!isFixedOpen) {
      // this forces the sidebar to close when the button in clicked
      setIsForceClosed(true)
      setTimeout(() => setIsForceClosed(false), 200)
    }
  }, [isFixedOpen, setIsForceClosed])

  useEffect(() => {
    const indexOfOpenSection = sectionItems
      .filter((sectionItem) => !!sectionItem?.rootUrl)
      .filter(
        (sectionItem) =>
          intersection(sectionItem?.requiredPermissions, userPermissions)
            .length > 0
      )
      .findIndex(
        (sectionItem) =>
          sectionItem?.rootUrl &&
          location.pathname.startsWith(sectionItem?.rootUrl)
      )
    setOpenAccordionItems(indexOfOpenSection)
  }, [location.pathname, sectionItems, setOpenAccordionItems, userPermissions])

  //collapse in resolutions smaller than 980px
  useEffect(() => {
    if (!isLargerThan980) {
      dispatch(setSidebarClosed())
    } else {
      dispatch(setSidebarOpen())
    }
  }, [isLargerThan980, dispatch])

  return {
    hoverRef,
    isFixedOpen,
    isSidebarOpen,
    onChangeAccordionItem,
    onToggleSidebarState,
    openAccordionItems,
  }
}

export default useSidebarHelper
