import { useCallback, useEffect, useRef, useState } from 'react'
import { FieldInputProps, FormikProps, FormikValues } from 'formik'
import { useQuery } from 'react-query'

type UseFormikFieldUniqueDebouncedProps<FormValues> = {
  queryFn: (value: string, signal?: AbortSignal) => Promise<boolean>
  virtualFieldName: keyof FormValues & string
  maxChars?: number
}

// todo: debounce should only be triggered if the field has no errors
const useFormikFieldUniqueDebounced = <FormValues extends FormikValues>({
  queryFn,
  virtualFieldName,
  maxChars,
}: UseFormikFieldUniqueDebouncedProps<FormValues>) => {
  const debounceRef = useRef<NodeJS.Timeout>()
  const [fieldValue, setFieldValue] = useState<string>('')
  const [form, setForm] = useState<FormikProps<FormValues>>()
  const [searchValue, setSearchValue] = useState<string>('')

  const { isFetching } = useQuery(
    [virtualFieldName, searchValue],
    ({ signal }) => queryFn(searchValue, signal),
    {
      enabled: !!searchValue && !!form,
      onError: () => {
        form!.setFieldValue(virtualFieldName, false)
      },
      onSuccess: (isUnique) => {
        form!.setFieldValue(virtualFieldName, isUnique)
      },
    }
  )

  const handleInputChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      formikField: FieldInputProps<string>,
      formikForm: FormikProps<FormValues>
    ) => {
      const { value } = event.target
      const valueSubstring = value.slice(0, maxChars)
      event.target.value = valueSubstring
      formikField.onChange(event)
      setFieldValue(valueSubstring)
      setForm(formikForm)
    },
    [maxChars]
  )

  useEffect(() => {
    if (debounceRef.current) clearTimeout(debounceRef.current)
    // check for unique value
    debounceRef.current = setTimeout(() => {
      setSearchValue(fieldValue)
    }, 300)
  }, [fieldValue])

  const hasValue = !!fieldValue
  const hasSearchTriggered = hasValue && searchValue !== fieldValue
  const isCheckingUniqueValue = isFetching || hasSearchTriggered

  return {
    handleInputChange,
    isCheckingUniqueValue,
  }
}

export default useFormikFieldUniqueDebounced
