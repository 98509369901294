import { FieldBaseProps } from '@/commons/types'
import { FormLabel, HStack, Text } from '@chakra-ui/react'

import HelpTextPopover from '@/components/atoms/HelpTextPopover'

export interface FieldLabelProps extends FieldBaseProps {
  hasError?: boolean
}

const RenderLabel = (
  LabelComponent: React.ReactNode | undefined,
  label: string | React.ReactNode | undefined,
  tooltipMessage: string | React.ReactNode,
  subLabel: string | undefined
) => {
  if (!!LabelComponent) return LabelComponent
  return (
    <HStack lineHeight={6} spacing={1}>
      <HelpTextPopover tooltipMessage={tooltipMessage} />
      <Text>{label}</Text>
      {subLabel && (
        <Text
          color='gray.500'
          fontSize='xs'
          fontWeight='normal'
          textTransform='none'
        >
          {subLabel}
        </Text>
      )}
    </HStack>
  )
}

const FieldLabel = ({
  hasError,
  label,
  LabelComponent,
  labelProps,
  name,
  subLabel,
  tooltipMessage,
}: FieldLabelProps) => (
  <FormLabel
    color={hasError ? 'red.500' : 'gray.700'}
    htmlFor={name}
    fontWeight='semibold'
    fontSize='sm'
    textTransform='capitalize'
    marginBottom={1}
    {...labelProps}
  >
    {RenderLabel(LabelComponent, label, tooltipMessage, subLabel)}
  </FormLabel>
)

export default FieldLabel
