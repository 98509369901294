import { useState, useEffect } from 'react'
import { useClipboard } from '@chakra-ui/react'

const useChakraClipboard = (value: string) => {
  const { onCopy, hasCopied } = useClipboard(value?.toString() || '')
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean | undefined>(
    undefined
  )

  // when the tooltip label is changed, it loses the hover state, so we have to force the state to be open for a second
  useEffect(() => {
    if (hasCopied) {
      setIsTooltipOpen(true)
      setTimeout(() => {
        // it's undefined because we want to reset the state to the default
        setIsTooltipOpen(undefined)
      }, 1000)
    }
  }, [hasCopied])

  return {
    onCopy,
    isTooltipOpen,
  }
}

export default useChakraClipboard
