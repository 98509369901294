export enum CreateStorageFragments {
  DuplicatedStorageId = 'DuplicatedStorageId',
  DuplicatedStorageName = 'DuplicatedStorageName',
  FileSystemMisconfigured = 'FileSystemMisconfigured',
  InvalidInput = 'InvalidInput',
  MissingAwsPermissions = 'MissingAwsPermissions',
  Storage = 'Storage',
  UnexpectedBehavior = 'UnexpectedBehavior',
}

export enum DeleteStorageFragments {
  StorageDeleted = 'StorageDeleted',
  StorageNotFound = 'StorageNotFound',
  UnexpectedBehavior = 'UnexpectedBehavior',
}

export enum DetachClusterFragments {
  InvalidInput = 'InvalidInput',
  StorageNotFound = 'StorageNotFound',
  StorageUnmounting = 'StorageUnmounting',
  UnexpectedBehavior = 'UnexpectedBehavior',
}

export enum MountClusterFragments {
  ClusterNotFound = 'ClusterNotFound',
  DuplicatedMountPoint = 'DuplicatedMountPoint',
  InvalidInput = 'InvalidInput',
  MountPoint = 'MountPoint',
  StorageNotFound = 'StorageNotFound',
  UnexpectedBehavior = 'UnexpectedBehavior',
}

export enum UpdateStorageFragments {
  DuplicatedStorageName = 'DuplicatedStorageName',
  Storage = 'Storage',
  StorageNotFound = 'StorageNotFound',
}
