import { TextProps } from '@chakra-ui/react'
import { TextFieldComponent } from '@/components/atoms'

export type DataTextFieldProps = {
  hasCopyButton?: boolean
  isParagraph?: boolean
  label?: string
  labelTextProps?: TextProps
  tooltipMessage?: string | React.ReactNode
  value?: string | number
  valueTextProps?: TextProps
}

const DataTextField = ({ value, ...props }: DataTextFieldProps) => {
  if (!value && value !== 0) return null
  return <TextFieldComponent {...props} value={value} />
}

export default DataTextField
