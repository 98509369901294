import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Box, Button, Text, VStack } from '@chakra-ui/react'
import { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion'
import { MentionItem } from '.'

export type SuggestionListRef = {
  onKeyDown: NonNullable<
    ReturnType<
      NonNullable<SuggestionOptions<MentionItem>['render']>
    >['onKeyDown']
  >
}

export type SuggestionListProps = SuggestionProps<MentionItem>

const MentionList = forwardRef<SuggestionListRef, SuggestionListProps>(
  (props, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0)

    const selectItem = (index: number) => {
      const item = props.items[index]

      if (item) {
        props.command(item)
      }
    }

    const upHandler = () => {
      setSelectedIndex(
        (selectedIndex + props.items.length - 1) % props.items.length
      )
    }

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % props.items.length)
    }

    const enterHandler = () => {
      selectItem(selectedIndex)
    }

    useEffect(() => setSelectedIndex(0), [props.items])

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }: any) => {
        if (event.key === 'ArrowUp') {
          upHandler()
          return true
        }

        if (event.key === 'ArrowDown') {
          downHandler()
          return true
        }

        if (event.key === 'Enter') {
          enterHandler()
          return true
        }

        return false
      },
    }))

    return (
      <Box
        backgroundColor='white'
        borderColor='gray.300'
        borderRadius='md'
        borderWidth='1px'
        boxShadow='md'
        width='318px'
      >
        <VStack spacing={0}>
          {props.items.length ? (
            props.items.map((item, index) => (
              <Button
                _active={{
                  backgroundColor: 'gray.100',
                  color: 'iris.500',
                  fontWeight: 'semibold',
                }}
                borderRadius={0}
                isActive={selectedIndex === index}
                justifyContent='flex-start'
                key={index}
                onClick={() => selectItem(index)}
                variant='ghost'
                width='100%'
                fontWeight='normal'
              >
                {item.label}
              </Button>
            ))
          ) : (
            <Text color='gray.500' paddingX={4} paddingY={2}>
              No users found.
            </Text>
          )}
        </VStack>
      </Box>
    )
  }
)

export default MentionList
