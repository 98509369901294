import { useCallback, useEffect, useState } from 'react'
import { FormikProps, FormikValues } from 'formik'
import { useHistory } from 'react-router-dom'
import { useDisclosure } from '@chakra-ui/react'

import { DirtyFormModalConfirmation } from '@/components/atoms'
import useIsFormDirty from './useIsFormDirty'

interface FormNavigationWrapperProps<Values> extends FormikProps<Values> {
  onClickCancel: (formikProps: FormikProps<Values>) => void
  hideSubmitButton?: boolean
  children: (
    props: FormikProps<Values> & {
      onClickCancel: () => void
    }
  ) => React.ReactNode
}

const FormNavigationWrapper = <Values extends FormikValues>({
  children,
  hideSubmitButton,
  onClickCancel,
  ...formikProps
}: FormNavigationWrapperProps<Values>) => {
  const history = useHistory()
  const { isOpen, onClose, onOpen } = useDisclosure()

  // local state
  const [originalPath, setOriginalPath] = useState<string>()
  const [shouldDiscardChanges, setShouldDiscardChanges] =
    useState<boolean>(false)

  // local variables
  const {
    handleSubmit,
    initialValues,
    isSubmitting,
    isValid,
    values,
    resetForm,
  } = formikProps

  const isDirty = useIsFormDirty({ initialValues, values })

  // handles cancel button click in any context
  const handleCancel = useCallback(() => {
    // resets the original path to avoid redirecting when the user cancels the form
    setOriginalPath('')
    if (isDirty && !shouldDiscardChanges) {
      onOpen()
    } else {
      onClickCancel(formikProps)
    }
  }, [formikProps, isDirty, onClickCancel, onOpen, shouldDiscardChanges])

  // blocks navigation if form is dirty and not submitted successfully
  useEffect(() => {
    const unblock = history.block((location) => {
      if (isDirty && !shouldDiscardChanges && !isSubmitting) {
        setOriginalPath(location.pathname)
        onOpen()
        return false
      }
    })
    return () => {
      unblock()
    }
  }, [history, isDirty, onOpen, shouldDiscardChanges, isSubmitting])

  // redirects to original path or closes the form if form is dirty and user decides to discard changes
  useEffect(() => {
    if (shouldDiscardChanges) {
      onClose()
      // cleanup handlers
      setShouldDiscardChanges(false)
      resetForm()
      // redirects to original path if there is one
      if (originalPath) history.push(originalPath)
      // if there is no original path, it means the user must be kept in the same page
      else handleCancel()
    }
  }, [
    handleCancel,
    history,
    onClickCancel,
    onClose,
    originalPath,
    shouldDiscardChanges,
    resetForm,
  ])

  // effect to handle the beforeunload event
  useEffect(() => {
    const onUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      e.returnValue = ''
    }

    if (isDirty) {
      window.addEventListener('beforeunload', onUnload)
    }
    if (!isDirty || shouldDiscardChanges) {
      window.removeEventListener('beforeunload', onUnload)
    }
    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [isDirty, shouldDiscardChanges])

  return (
    <>
      <DirtyFormModalConfirmation
        handleSubmit={handleSubmit}
        hideSubmitButton={hideSubmitButton}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
        isValid={isValid}
        onExitForm={() => setShouldDiscardChanges(true)}
        onKeepEditing={onClose}
      />
      {children({ ...formikProps, onClickCancel: handleCancel })}
    </>
  )
}

export default FormNavigationWrapper
