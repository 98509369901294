import {
  chakra,
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Text,
  TextProps,
  Tooltip,
} from '@chakra-ui/react'
import { HelpTextPopover } from '@/components/atoms'
import { useChakraClipboard } from '@/hooks'
import { BiCopy, BiLinkExternal } from 'react-icons/bi'

type DataTextPreviewFieldProps = {
  hasCopyButton?: boolean
  label?: string
  labelTextProps?: TextProps
  tooltipMessage?: string | React.ReactNode
  value?: string | number
  valueTextProps?: TextProps
  onClickPreview: (event: React.MouseEvent<HTMLButtonElement>) => void
  isDisabled?: boolean
}

const DataTextPreviewField = ({
  hasCopyButton,
  isDisabled,
  label,
  labelTextProps,
  onClickPreview,
  tooltipMessage,
  value,
  valueTextProps,
}: DataTextPreviewFieldProps) => {
  const { onCopy, isTooltipOpen } = useChakraClipboard(value?.toString() ?? '')
  const clipboardTooltipLabel = isTooltipOpen
    ? `${label} copied to clipboard!`
    : `Copy ${label} to clipboard`

  const previewButtonTooltipLabel = isDisabled
    ? `${label} not found`
    : `Open ${label} Preview`

  if (!value) return null
  return (
    <Box>
      <HStack spacing={2}>
        {!!label && (
          <HStack spacing={1} width='fit-content'>
            <HelpTextPopover tooltipMessage={tooltipMessage} />
            <Text
              fontSize='sm'
              color='black'
              fontWeight='semibold'
              whiteSpace='nowrap'
              {...labelTextProps}
            >
              {label}:
            </Text>
          </HStack>
        )}
        <HStack spacing={2} overflow='hidden'>
          <Tooltip
            hasArrow
            label={previewButtonTooltipLabel}
            offset={[-5, 5]}
            placement='top'
          >
            <Box width='100%'>
              <Button
                _active={{
                  boxShadow: 'none',
                  backgroundColor: 'transparent',
                }}
                _focus={{
                  boxShadow: 'none',
                }}
                _hover={{
                  textDecoration: 'underline',
                  backgroundColor: 'transparent',
                }}
                aria-label='open preview'
                color='iris.500'
                fontSize='sm'
                height='fit-content'
                isDisabled={isDisabled}
                margin={0}
                onClick={onClickPreview}
                padding={0}
                variant='ghost'
                width='100%'
              >
                <chakra.span
                  overflow='hidden'
                  textOverflow='ellipsis'
                  whiteSpace='nowrap'
                  {...valueTextProps}
                >
                  {value}
                </chakra.span>
                <Icon as={BiLinkExternal} boxSize='14px' marginLeft={2} />
              </Button>
            </Box>
          </Tooltip>
          {hasCopyButton && value === '-' && (
            <Tooltip
              hasArrow
              isOpen={isTooltipOpen}
              label={clipboardTooltipLabel}
              offset={[-5, 5]}
              placement='top'
            >
              <Box boxSize='fit-content'>
                <IconButton
                  _focus={{
                    boxShadow: 'none',
                  }}
                  aria-label='copy to clipboard'
                  icon={<Icon as={BiCopy} />}
                  onClick={onCopy}
                  variant='icon-button-ghost'
                />
              </Box>
            </Tooltip>
          )}
        </HStack>
      </HStack>
    </Box>
  )
}

export default DataTextPreviewField
