import { uniq } from 'lodash'
import { Axios } from '@/commons/helpers'
import queryService from '@/commons/helpers/queryService'
import {
  ActionsResponse,
  MemberListResponse,
  WatcherResponse,
} from '@/commons/interfaces'
import { GraphQLQueryVariables } from '@/commons/types'
import {
  getPromiseSettledValue,
  makeAlertsFromWatchers,
  sortNotifications,
} from './utils'

const watcherFields = {
  id: true,
  userEmail: true,
  createdAt: true,
  path: true,
  alerts: {
    id: true,
    checked: true,
    createdAt: true,
    actions: {
      additionalContext: true,
      path: true,
      userEmail: true,
    },
  },
}

const actionFields = {
  actionType: true,
  additionalContext: true,
  alerts: {
    id: true,
    checked: true,
  },
  createdAt: true,
  id: true,
  path: true,
  userEmail: true,
}

const fetchUserName = (userEmail: string) =>
  Axios.get<MemberListResponse>(
    `admin/management/organizations/members?search=${userEmail}`
  ).then((response) => ({
    name: response.data?.users[0]?.name,
    email: userEmail,
  }))

const hydrateAlertsWithUserName = async (
  watchers: WatcherResponse[] | undefined
) => {
  const alertsUserEmails = uniq(
    watchers?.reduce<string[]>((acc, curr) => {
      const userEmails = curr.alerts?.map(
        (alert) => alert?.actions?.userEmail
      ) as string[]
      if (!userEmails) return acc
      return acc.concat([...userEmails])
    }, [])
  )
  if (watchers && alertsUserEmails && alertsUserEmails.length > 0) {
    const users = await Promise.allSettled(
      alertsUserEmails.map((userEmail) => fetchUserName(userEmail))
    ).then((results) => {
      const successfulFetches = results.filter(
        (user) => user.status === 'fulfilled' && !!user.value.name
      ) as PromiseFulfilledResult<{ name: string; email: string }>[]
      return successfulFetches.map((user) => user.value)
    })

    watchers.forEach((watcher) => {
      watcher.alerts?.forEach((alert) => {
        if (!alert?.actions) return
        alert.actions.userName =
          users.find((user) => user.email === alert?.actions?.userEmail)
            ?.name || alert.actions.userEmail
      })
    })
  }
}

const fetchWatchers = async (userEmail?: string) => {
  try {
    let variables: GraphQLQueryVariables<Partial<WatcherResponse>> = {
      first: 1000,
      after: 0,
      filters: { userEmail: { eq: userEmail } },
    }
    const watchers = await queryService<'watchers', WatcherResponse>({
      entityName: 'watchers',
      route: '/notifications/graphql',
      variables,
      queryFields: watcherFields,
      omitTotal: true,
      omitSubfilters: true,
    }).then((response) => response?.data!)
    await hydrateAlertsWithUserName(watchers)
    return watchers
  } catch (error) {
    console.error(error)
    return []
  }
}

const fetchMentions = async (userEmail?: string) => {
  try {
    let variables: GraphQLQueryVariables<Partial<ActionsResponse>> = {
      first: 1000,
      after: 0,
      filters: { userEmail: { eq: userEmail }, actionType: { eq: 'mention' } },
    }
    const actions = await queryService<'actions', ActionsResponse>({
      entityName: 'actions',
      route: '/notifications/graphql',
      variables,
      queryFields: actionFields,
      omitTotal: true,
      omitSubfilters: true,
    }).then((response) => response?.data!)
    return actions
  } catch (error) {
    console.error(error)
    return []
  }
}

const fetchSubmissionStatus = async (userEmail?: string) => {
  try {
    let variables: GraphQLQueryVariables<Partial<ActionsResponse>> = {
      first: 1000,
      after: 0,
      filters: { userEmail: { eq: userEmail }, actionType: { eq: 'status' } },
    }
    const actions = await queryService<'actions', ActionsResponse>({
      entityName: 'actions',
      route: '/notifications/graphql',
      variables,
      queryFields: actionFields,
      omitTotal: true,
      omitSubfilters: true,
    }).then((response) => response?.data!)
    return actions
  } catch (error) {
    console.error(error)
    return []
  }
}

const fetchWatchersAndMentions = async (userEmail?: string) => {
  const [watcherResponse, actionResponse, statusResponse] =
    await Promise.allSettled([
      fetchWatchers(userEmail),
      fetchMentions(userEmail),
      fetchSubmissionStatus(userEmail),
    ])

  const alerts = makeAlertsFromWatchers(getPromiseSettledValue(watcherResponse))
  const mentions = getPromiseSettledValue(actionResponse)
  const submissions = getPromiseSettledValue(statusResponse)

  return sortNotifications([...alerts, ...mentions, ...submissions])
}

const checkAllAlerts = () =>
  Axios.post(`/notifications/graphql`, {
    query: `mutation CheckAllAlerts {
    markAlertsAsChecked(all: true) {
        message
      }
    }`,
  })

export { checkAllAlerts, fetchWatchers, fetchWatchersAndMentions }
