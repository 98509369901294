const Modal = {
  parts: [
    'header',
    'overlay',
    'dialogContainer',
    'dialog',
    'closeButton',
    'body',
    'footer',
  ],
  baseStyle: {
    dialogContainer: {
      overflow: 'hidden',
    },
    header: {
      color: 'gray.700',
      fontSize: 'md',
      paddingBottom: 5,
      paddingInlineEnd: 5,
      paddingInlineStart: 5,
      paddingTop: 6,
    },
    body: {
      maxHeight: '80vh',
      overflowY: 'auto',
      paddingBottom: 0,
      paddingInlineEnd: 5,
      paddingInlineStart: 5,
      paddingTop: 0,
    },
    footer: {
      paddingBottom: 5,
      paddingInlineStart: 5,
      paddingInlineEnd: 5,
      paddingTop: 5,
    },
    overlay: {
      bg: 'russianViolet.600',
      opacity: '0.8 !important',
    },
  },
}

export default Modal
