import {
  Box,
  Button,
  ButtonProps,
  Icon,
  IconProps,
  Tooltip,
  TooltipProps,
} from '@chakra-ui/react'
import { ChangeEvent, useCallback, useRef } from 'react'
import { BiPaperclip } from 'react-icons/bi'

type FileUploadButtonProps = {
  buttonProps?: Omit<ButtonProps, 'children' | 'onClick'>
  iconProps?: IconProps
  onFileUpload: (event: ChangeEvent<HTMLInputElement>) => void
  tooltipProps?: Omit<TooltipProps, 'children'>
}

const FileUploadButton = ({
  buttonProps,
  iconProps,
  onFileUpload,
  tooltipProps,
}: FileUploadButtonProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleUploadFileClick = useCallback(() => {
    if (!inputRef.current) return
    inputRef.current.click()
  }, [])

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onFileUpload(event)
      // clear the input value to allow the same file to be uploaded again
      event.target.value = ''
    },
    [onFileUpload]
  )

  return (
    <>
      <input
        multiple
        onChange={handleInputChange}
        ref={inputRef}
        style={{ display: 'none' }}
        type='file'
      />
      <Tooltip
        hasArrow
        label='Attach files'
        placement='top-end'
        {...tooltipProps}
      >
        <Box boxSize='fit-content'>
          <Button
            aria-label='file upload'
            onClick={handleUploadFileClick}
            size='sm'
            variant='ghost'
            {...buttonProps}
          >
            <Icon
              as={BiPaperclip}
              boxSize='16px'
              transform='rotate(-90deg)'
              {...iconProps}
            />
          </Button>
        </Box>
      </Tooltip>
    </>
  )
}

export default FileUploadButton
