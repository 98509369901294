type SuccessFragment<Fragments extends Record<string, string>> = {
  __typeName: keyof Fragments
  [key: string]: any
}

type ErrorFragment = {
  __typename: true
  message: true
  [key: string]: any
}

// typeName is the key of the fragments object
// typename is the field of the response object

const createMutationFragments = <Fragments extends Record<string, string>>(
  fragments: Fragments,
  successFragment: SuccessFragment<Fragments>,
  errorFragment?: ErrorFragment
) =>
  Object.keys(fragments).map((key: keyof typeof fragments) => {
    if (key === successFragment.__typeName) {
      return { __typename: true, ...successFragment }
    }
    // custom error fragment
    if (errorFragment) return { __typeName: key, ...errorFragment }

    return {
      __typeName: fragments[key],
      message: true,
      __typename: true,
    }
  })

export default createMutationFragments
