type appEnv = 'dev' | 'production' | 'qa' | 'staging'

const apiBaseUrl = process.env.REACT_APP_BASE_URL as string
const docsUrl = process.env.REACT_APP_DOCS_URL as string
const envGtag = process.env.REACT_APP_GTAG as string
const hotjarId = process.env.REACT_APP_HOTJAR_ID
  ? Number(process.env.REACT_APP_HOTJAR_ID)
  : undefined
const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION
  ? Number(process.env.REACT_APP_HOTJAR_VERSION)
  : undefined
const keycloakClient = process.env.REACT_APP_KEYCLOAK_CLIENT as string
const keycloakLogoutRedirectUrl = process.env
  .REACT_APP_LOGOUT_REDIRECT_URL as string
const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL as string
const privacyPageDocumentUrl = process.env.REACT_APP_POLICY_FILE as string
const quickStackTemplateLink = process.env.REACT_APP_QS_TEMPLATE_LINK as string
const reactAppEnv = process.env.REACT_APP_ENV as appEnv
const reactAppVersion = process.env.REACT_APP_VERSION as string
const sentryDsn = process.env.REACT_APP_SENTRY_DSN as string

export {
  apiBaseUrl,
  docsUrl,
  envGtag,
  hotjarId,
  hotjarVersion,
  keycloakClient,
  keycloakLogoutRedirectUrl,
  keycloakUrl,
  privacyPageDocumentUrl,
  quickStackTemplateLink,
  reactAppEnv,
  reactAppVersion,
  sentryDsn,
}
