import { HStack } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { ActionButton } from '@/components/atoms'

type NoCloudAccountsBannerProps = {
  children?: React.ReactNode
}

const NoCloudAccountsBanner = ({ children }: NoCloudAccountsBannerProps) => {
  const history = useHistory()
  return (
    <HStack
      backgroundColor='iris.50'
      borderRadius='md'
      paddingX={4}
      paddingY={3}
      spacing={3}
      width='100%'
    >
      {children}
      <ActionButton
        onClick={() => history.push('/admin/cloud-accounts')}
        requiredPermissions={['admin:cloud-accounts:edit']}
      >
        Go to Cloud Accounts
      </ActionButton>
    </HStack>
  )
}

export default NoCloudAccountsBanner
