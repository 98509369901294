import { JSONContent } from '@tiptap/react'

interface ParsedTextObject {
  type: string
  content?: (ParsedTextObject | ParsedMentionObject)[]
  text?: string
}

interface ParsedMentionObject {
  type: string
  attrs: {
    id: string
    label: string
  }
}

const parseParagraph = (
  paragraphText: string
): (ParsedTextObject | ParsedMentionObject)[] => {
  const mentionsRegex = /\((.*?)\)\[(.*?)\]/g
  const content: (ParsedTextObject | ParsedMentionObject)[] = []
  let match

  while ((match = mentionsRegex.exec(paragraphText)) !== null) {
    // Add preceding text as text object
    if (match.index > 0) {
      const precedingText = paragraphText.substring(0, match.index)
      content.push({
        type: 'text',
        text: precedingText,
      })
    }

    // Add mention object
    content.push({
      type: 'mention',
      attrs: {
        id: match[2],
        label: match[1],
      },
    })

    // Update paragraphText to skip the processed part
    paragraphText = paragraphText.substring(match.index + match[0].length)
  }

  // Add the remaining text as text object
  if (paragraphText.length > 0) {
    content.push({
      type: 'text',
      text: paragraphText,
    })
  }

  return content
}

const parseTextToTipTapObject = (text: string): JSONContent => {
  const paragraphs = text.split('\n').map((paragraphText) => {
    return {
      type: 'paragraph',
      content: parseParagraph(paragraphText),
    }
  })

  return { type: 'doc', content: paragraphs }
}

export default parseTextToTipTapObject
