import React, { useState } from 'react'
import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Icon,
  chakra,
  Flex,
  VStack,
  Text,
  Alert,
  AlertIcon,
  useDisclosure,
  PopoverProps,
} from '@chakra-ui/react'
import {
  BiChevronRight,
  BiPlusCircle,
  BiChevronDown,
  BiSad,
} from 'react-icons/bi'

import {
  ActionButton,
  AddOptionsDropdownItem,
  BoldQuery,
  SearchInput,
} from '@/components/atoms'
import { useScroll } from '@/hooks'
import { AddDropdownOption } from '@/components/atoms/AddOptionsDropdownItem'

type AddItemDropdownProps = {
  allOptionsAddedMessage: string
  draftRows: AddDropdownOption[]
  onChangeOptions: (options: AddDropdownOption[]) => void
  onClick?: () => void
  options: AddDropdownOption[]
  searchPlaceholder?: string
  placement?: PopoverProps['placement']
  parentRef?: React.RefObject<HTMLDivElement>
}

const AddItemDropdown = ({
  allOptionsAddedMessage,
  draftRows,
  onChangeOptions,
  onClick,
  options,
  parentRef,
  placement = 'bottom-end',
  searchPlaceholder,
}: AddItemDropdownProps) => {
  const [searchValue, setSearchValue] = useState('')
  const { isOpen, onClose, onOpen } = useDisclosure()
  // close on scroll to avoid wrong overflow in modals
  // ideally zIndex components shouldn't be used over modals
  useScroll(parentRef, onClose)

  const addedOptions = draftRows.map(({ value }) => value)

  const filteredOptions = options
    .filter((option) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase())
    )
    .filter((option) => !addedOptions.includes(option.value))

  const handleAddOptionToDraft = (option: AddDropdownOption) =>
    onChangeOptions(draftRows.concat(option))

  const handleClickTriggerButton = () => {
    if (onClick) onClick()
    onOpen()
  }

  return (
    <Popover
      isLazy
      isOpen={isOpen}
      onClose={onClose}
      placement={placement}
      variant='solid'
    >
      <PopoverTrigger>
        <ActionButton
          leftIcon={<BiPlusCircle />}
          onClick={handleClickTriggerButton}
          rightIcon={isOpen ? <BiChevronDown /> : <BiChevronRight />}
          size='md'
          variant='solid'
          requiredPermissions={['admin:users:edit']}
        >
          Add Group
        </ActionButton>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <Box padding={4}>
            <SearchInput
              value={searchValue}
              onChange={setSearchValue}
              placeholder={searchPlaceholder}
              isDisabled={addedOptions.length === options.length}
            />
          </Box>
          <Box>
            {filteredOptions.map((option) => (
              <AddOptionsDropdownItem
                key={option.value}
                handleAddOptionToDraft={handleAddOptionToDraft}
                option={option}
              >
                {!searchValue && (
                  <chakra.span fontWeight='normal'>{option.label}</chakra.span>
                )}
                {!!searchValue && (
                  <BoldQuery str={option.label} query={searchValue} />
                )}
              </AddOptionsDropdownItem>
            ))}
            {!searchValue && addedOptions.length === options.length && (
              <Box paddingX={4}>
                <Alert
                  status='info'
                  backgroundColor='blue.50'
                  borderRadius='6px'
                >
                  <AlertIcon />
                  <chakra.span color='gray.700' fontSize='sm'>
                    {allOptionsAddedMessage}
                  </chakra.span>
                </Alert>
              </Box>
            )}
            {searchValue && filteredOptions.length === 0 && (
              <Flex justify='center' alignItems='center' paddingX={4}>
                <VStack>
                  <Icon as={BiSad} color='fluorescentBlue.500' boxSize='40px' />
                  <Box textAlign='center'>
                    <Text fontSize='lg' fontWeight='semibold' color='gray.600'>
                      No results found.
                    </Text>
                    <Text fontSize='xs' color='gray.500'>
                      Try adjusting your search or filter to find what you're
                      looking for.
                    </Text>
                  </Box>
                </VStack>
              </Flex>
            )}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default AddItemDropdown
