import { useEffect, useRef, useState } from 'react'
import { FormikValues } from 'formik'
import { isEqual } from 'lodash'

type UseIsFormDirty = {
  values: FormikValues
  initialValues: FormikValues
}

const useIsFormDirty = ({ initialValues, values }: UseIsFormDirty) => {
  const debounceRef = useRef<NodeJS.Timeout | null>(null)
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)
  useEffect(() => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current)
    }
    debounceRef.current = setTimeout(() => {
      setIsFormDirty(!isEqual(initialValues, values))
    }, 300)
  }, [initialValues, values])

  return isFormDirty
}

export default useIsFormDirty
