export enum CreateClusterFragments {
  Cluster = 'Cluster',
  ClusterCouldNotBeDeployed = 'ClusterCouldNotBeDeployed',
  ClusterNameInUse = 'ClusterNameInUse',
  InvalidInput = 'InvalidInput',
  InvalidProviderInput = 'InvalidProviderInput',
  UnexpectedBehavior = 'UnexpectedBehavior',
}

export enum DeleteClusterFragments {
  ClusterDeleted = 'ClusterDeleted',
  ClusterNotFound = 'ClusterNotFound',
  InvalidProviderInput = 'InvalidProviderInput',
  UnexpectedBehavior = 'UnexpectedBehavior',
}

export enum UpdateClusterFragments {
  Cluster = 'Cluster',
  ClusterNotFound = 'ClusterNotFound',
}
