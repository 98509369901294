import Mention, { MentionOptions } from '@tiptap/extension-mention'
import Placeholder from '@tiptap/extension-placeholder'
import { ReactRenderer } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import tippy, { type Instance as TippyInstance } from 'tippy.js'
import { MentionItem } from '.'

import MentionList, { SuggestionListRef } from './MentionList'

type UseExtensionsProps = {
  items: MentionItem[]
  name: string
  placeholder?: string
}

/**
 * Workaround for the current typing incompatibility between Tippy.js and Tiptap
 * Suggestion utility.
 *
 * @see https://github.com/ueberdosis/tiptap/issues/2795#issuecomment-1160623792
 *
 * Adopted from
 * https://github.com/Doist/typist/blob/a1726a6be089e3e1452def641dfcfc622ac3e942/stories/typist-editor/constants/suggestions.ts#L169-L186
 */
const DOM_RECT_FALLBACK: DOMRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
  toJSON() {
    return {}
  },
}

const makeSuggestion = (
  items: MentionItem[],
  name: string
): MentionOptions['suggestion'] => ({
  items: ({ query }) =>
    items
      .filter((item) =>
        item.label.toLowerCase().startsWith(query.toLowerCase())
      )
      .slice(0, 5),
  render: () => {
    let component: ReactRenderer<SuggestionListRef> | undefined
    let popup: TippyInstance | undefined

    return {
      onStart: (props) => {
        component = new ReactRenderer(MentionList, {
          props: { ...props, name },
          editor: props.editor,
        })

        popup = tippy('body', {
          getReferenceClientRect: () =>
            props.clientRect?.() ?? DOM_RECT_FALLBACK,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })[0]
      },

      onUpdate(props) {
        component?.updateProps(props)

        popup?.setProps({
          getReferenceClientRect: () =>
            props.clientRect?.() ?? DOM_RECT_FALLBACK,
        })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup?.hide()
          return true
        }

        if (!component?.ref) {
          return false
        }

        return component.ref.onKeyDown(props)
      },

      onExit() {
        popup?.destroy()
        component?.destroy()

        popup = undefined
        component = undefined
      },
    }
  },
})

const useExtensions = ({ items, name, placeholder }: UseExtensionsProps) => [
  StarterKit,
  Placeholder.configure({
    placeholder,
  }),
  Mention.configure({
    HTMLAttributes: {
      class: 'mention',
    },
    suggestion: makeSuggestion(items, name),
  }),
]

export default useExtensions
