import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import { reactAppEnv, sentryDsn } from './commons/helpers/LocalEnvData'

if (reactAppEnv === 'staging' || reactAppEnv === 'production') {
  Sentry.init({
    dsn: sentryDsn,
    environment: reactAppEnv,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
      new Sentry.BrowserProfilingIntegration(),
    ],
    tracePropagationTargets: [
      /^https:\/\/app\.staging\.vantagehpc\.io/, // staging
      /^https:\/\/app\.vantagehpc\.io/, // prod
    ],
    profilesSampleRate: 1.0,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
