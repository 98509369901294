import extensionToLanguageHash from '../constants/extensionToLanguageHash.json'
import shebangToLanguageHash from '../constants/shebangToLanguageHash.json'

const getEditorLanguage = (
  fileExtension: string = '',
  fileContent: string = ''
) => {
  let language = 'plaintext'
  const extensionToLanguage = (
    extensionToLanguageHash as Record<string, string>
  )[fileExtension]

  if (extensionToLanguage) {
    language = extensionToLanguage
  }

  const shebangDetectorRegex = new RegExp(/^#!\s*\S+/)
  const fileShebang = fileContent.match(shebangDetectorRegex)?.[0]

  if (fileShebang) {
    const shebangToLanguage = (shebangToLanguageHash as Record<string, string>)[
      fileShebang
    ]
    if (shebangToLanguage) {
      language = shebangToLanguage
    }
  }

  return language
}

export default getEditorLanguage
