import { Flex, Icon, Spinner, Text, Tooltip } from '@chakra-ui/react'
import { BiBlock } from 'react-icons/bi'
import { JobSubmissionsStatuses } from '@/commons/enums'
import { JobSubmissionData } from '@/jobs/Pages/JobSubmissions/job-submissions.type'

type SlurmJobStateValueProps = {
  data: Omit<JobSubmissionData, 'execution_parameters'>
  hideOtherStatuses?: boolean
}

const SlurmJobStateValue = ({
  data,
  hideOtherStatuses,
}: SlurmJobStateValueProps) => {
  const { status, slurm_job_state } = data
  if (!slurm_job_state) {
    if (
      status === JobSubmissionsStatuses.CREATED ||
      status === JobSubmissionsStatuses.SUBMITTED
    ) {
      return <Spinner color='iris.500' size='sm' />
    }
    if (status === JobSubmissionsStatuses.REJECTED) {
      return (
        <Tooltip
          hasArrow
          label='The job was rejected by Slurm'
          placement='top-start'
          offset={[-5, hideOtherStatuses ? 0 : 5]}
        >
          <Flex alignItems='center' height='100%' width='fit-content'>
            <Icon as={BiBlock} boxSize={4} color='gray.600' />
          </Flex>
        </Tooltip>
      )
    }
  }
  if (hideOtherStatuses) return null
  return <Text>{slurm_job_state}</Text>
}

export default SlurmJobStateValue
