import { SectionItem } from '@/components/molecules'
import { useKeycloak } from '@/hooks'
import { Box, Accordion } from '@chakra-ui/react'
import { IconType } from 'react-icons'

type SubSectionItemProps = {
  dataTour?: string
  externalUrl?: string
  linkItems?: {
    name: string
    route: string
    dataTour?: string
    requiredPermissions?: string[]
  }[]
  name: string
  rootUrl?: string
  route?: string
  SectionIcon: IconType
  requiredPermissions?: string[]
}

type SidebarSubSectionProps = {
  isSidebarOpen: boolean
  onChangeAccordionItem: (openIndexes: any) => void
  openAccordionItems: number | undefined
  SubSectionItems: SubSectionItemProps[]
}

const getSubSectionItems = (
  SubSectionItems: SubSectionItemProps[],
  hasOrganizationInToken: boolean,
  userPermissions: string[]
) => {
  // org creation flow - the user is going to be an admin
  if (!hasOrganizationInToken) return SubSectionItems
  return SubSectionItems.filter((item) =>
    userPermissions.some(
      (permission) =>
        !item.requiredPermissions ||
        !!item.requiredPermissions?.includes(permission)
    )
  )
}

const SidebarSubSection = ({
  isSidebarOpen,
  onChangeAccordionItem,
  openAccordionItems,
  SubSectionItems,
}: SidebarSubSectionProps) => {
  const { userPermissions, hasOrganizationInToken } = useKeycloak()
  return (
    <Box marginTop={5}>
      <Accordion
        variant='ghost'
        borderColor='transparent'
        marginTop={1}
        allowToggle
        onChange={onChangeAccordionItem}
        index={isSidebarOpen ? openAccordionItems : []}
      >
        {getSubSectionItems(
          SubSectionItems,
          hasOrganizationInToken,
          userPermissions
        ).map((item) => {
          return (
            <SectionItem
              dataTour={item.dataTour}
              externalUrl={item.externalUrl}
              isSidebarOpen={isSidebarOpen}
              key={item.name}
              linkItems={item.linkItems}
              name={item.name}
              rootUrl={item.rootUrl}
              route={item.route}
              SectionIcon={item.SectionIcon}
            />
          )
        })}
      </Accordion>
    </Box>
  )
}

export default SidebarSubSection
