import { chakra, HStack, Icon, Text, VStack } from '@chakra-ui/react'
import { BiHeadphone } from 'react-icons/bi'

import { fromISOToRelative } from '@/commons/helpers'
import { AlertResponse } from '@/commons/interfaces'
import { AvatarWithInitials, BaseNotificationItem } from '@/components/atoms'

type NotificationMenuAlertItemProps = {
  alert: AlertResponse
  refetch: () => void
}

const NotificationMenuAlertItem = ({
  alert,
  refetch,
}: NotificationMenuAlertItemProps) => {
  const { comment_id, ticket_id } = alert?.actions?.additionalContext

  return (
    <BaseNotificationItem
      alert={alert}
      Avatar={
        <AvatarWithInitials
          name={alert?.actions?.userName || alert?.actions?.userEmail}
        />
      }
      redirectRoute={`/support/${ticket_id}#comment-${comment_id}`}
      refetch={refetch}
    >
      <VStack
        height='48px'
        justifyContent='center'
        spacing={0}
        alignItems='flex-start'
      >
        <Text color='black'>
          <chakra.b>{alert?.actions?.userEmail}</chakra.b> commented in Ticket{' '}
          {ticket_id}
        </Text>
        <HStack
          color='gray.600'
          justify='space-between'
          width='100%'
          fontSize='xs'
        >
          <HStack spacing={1}>
            <Icon as={BiHeadphone} boxSize={3} color='gray.600' />
            <chakra.span>Support</chakra.span>
          </HStack>
          <chakra.span>
            {fromISOToRelative(alert?.actions?.createdAt)}
          </chakra.span>
        </HStack>
      </VStack>
    </BaseNotificationItem>
  )
}

export default NotificationMenuAlertItem
