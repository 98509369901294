import { chakra, HStack, Icon, Text, VStack } from '@chakra-ui/react'
import { BiHeadphone } from 'react-icons/bi'

import { fromISOToRelative } from '@/commons/helpers'
import { ActionsResponse } from '@/commons/interfaces'
import { AvatarWithInitials, BaseNotificationItem } from '@/components/atoms'
import { useKeycloak } from '@/hooks'

type NotificationMenuAlertItemProps = {
  action: ActionsResponse
  refetch: () => void
}

const NotificationMenuAlertItem = ({
  action,
  refetch,
}: NotificationMenuAlertItemProps) => {
  const { user } = useKeycloak()
  const { comment_id, ticket_id } = action.additionalContext
  const relatedAlert = action.alerts[0]

  return (
    <BaseNotificationItem
      alert={relatedAlert}
      Avatar={
        <AvatarWithInitials name={user?.profile.name || user?.profile.email} />
      }
      redirectRoute={`/support/${ticket_id}#comment-${comment_id}`}
      refetch={refetch}
    >
      <VStack
        height='48px'
        justifyContent='center'
        spacing={0}
        alignItems='flex-start'
      >
        <Text color='black'>
          <chakra.b>You</chakra.b> were mentioned in a comment.
        </Text>
        <HStack
          color='gray.600'
          justify='space-between'
          width='100%'
          fontSize='xs'
        >
          <HStack spacing={1}>
            <Icon as={BiHeadphone} boxSize={3} color='gray.600' />
            <chakra.span>Support</chakra.span>
          </HStack>
          <chakra.span>{fromISOToRelative(action.createdAt)}</chakra.span>
        </HStack>
      </VStack>
    </BaseNotificationItem>
  )
}

export default NotificationMenuAlertItem
