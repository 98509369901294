import { Avatar, chakra, HStack, Icon, Text, VStack } from '@chakra-ui/react'
import { BiAddToQueue } from 'react-icons/bi'

import VantageLogo from '@/assets/images/VantageLogo.svg'
import { fromISOToRelative } from '@/commons/helpers'
import { ActionsResponse } from '@/commons/interfaces'
import BaseNotificationItem from '@/components/atoms/BaseNotificationItem'

type NotificationMenuStatusItemProps = {
  action: ActionsResponse
  refetch: () => void
}

type NotificationStatusTextProps = {
  jobSubmissionId: number
}

const getJobSubmissionId = (path: string) => {
  const pathArray = path.split('.')
  const id = Number(pathArray.pop())
  if (isNaN(id)) return null
  return id
}

const JobCompletedText = ({ jobSubmissionId }: NotificationStatusTextProps) => (
  <Text>
    Your{' '}
    <chakra.span fontWeight='bold'>
      Job Submission #{jobSubmissionId}
    </chakra.span>{' '}
    was completed successfully.
  </Text>
)

const JobAbortedText = ({ jobSubmissionId }: NotificationStatusTextProps) => (
  <Text>
    Your{' '}
    <chakra.span fontWeight='bold'>
      Job Submission #{jobSubmissionId}
    </chakra.span>{' '}
    could not be completed.
  </Text>
)

const JobRejectedText = ({ jobSubmissionId }: NotificationStatusTextProps) => (
  <Text>
    Slurm could not process your{' '}
    <chakra.span fontWeight='bold'>
      Job Submission #{jobSubmissionId}.
    </chakra.span>
  </Text>
)

const NotificationMenuStatusItem = ({
  action,
  refetch,
}: NotificationMenuStatusItemProps) => {
  const jobSubmissionId = getJobSubmissionId(action.path)
  const { status } = action.additionalContext
  const relatedAlert = action.alerts[0]

  if (!jobSubmissionId) return null

  return (
    <BaseNotificationItem
      alert={relatedAlert}
      Avatar={
        <Avatar
          backgroundColor='white'
          showBorder
          borderWidth='2px'
          borderColor='iris.100'
          src={VantageLogo}
          sx={{
            img: {
              width: '32px',
              objectFit: 'contain',
            },
          }}
        />
      }
      redirectRoute={`/jobs/job-submissions/${jobSubmissionId}`}
      refetch={refetch}
    >
      <VStack
        height='48px'
        justifyContent='center'
        spacing={0}
        alignItems='flex-start'
      >
        {status === 'DONE' && (
          <JobCompletedText jobSubmissionId={jobSubmissionId} />
        )}
        {status === 'ABORTED' && (
          <JobAbortedText jobSubmissionId={jobSubmissionId} />
        )}
        {status === 'REJECTED' && (
          <JobRejectedText jobSubmissionId={jobSubmissionId} />
        )}
        <HStack
          color='gray.600'
          justify='space-between'
          width='100%'
          fontSize='xs'
        >
          <HStack spacing={1}>
            <Icon as={BiAddToQueue} boxSize={3} color='gray.600' />
            <chakra.span>Job Submission</chakra.span>
          </HStack>
          <chakra.span>{fromISOToRelative(action.createdAt)}</chakra.span>
        </HStack>
      </VStack>
    </BaseNotificationItem>
  )
}

export default NotificationMenuStatusItem
