import {
  Box,
  Button,
  ButtonProps,
  forwardRef,
  Tooltip,
  TooltipProps,
} from '@chakra-ui/react'

import { checkForNecessaryPermissions } from '@/commons/helpers'
import { useKeycloak } from '@/hooks'

type ActionButtonProps = ButtonProps & {
  requiredPermissions?: string[]
  tooltipProps?: Omit<TooltipProps, 'children'>
}

const ActionButton = forwardRef<ActionButtonProps, 'button'>(
  (
    { children, isDisabled, requiredPermissions, tooltipProps, ...props },
    ref
  ) => {
    const { userPermissions } = useKeycloak()
    const hasNecessaryPermissions = checkForNecessaryPermissions(
      userPermissions,
      requiredPermissions
    )
    const isButtonDisabled = isDisabled ? true : !hasNecessaryPermissions
    const { label, ...resTooltipProps } = tooltipProps ?? {}
    const tooltipLabel = !hasNecessaryPermissions
      ? "You don't have permission to perform this action."
      : label

    return (
      <Tooltip
        hasArrow
        label={tooltipLabel}
        placement='top-end'
        isDisabled={!isButtonDisabled}
        {...resTooltipProps}
      >
        <Box width='fit-content'>
          <Button isDisabled={isButtonDisabled} {...props} ref={ref}>
            {children}
          </Button>
        </Box>
      </Tooltip>
    )
  }
)

export default ActionButton
