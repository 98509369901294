import { useEffect } from 'react'

import { keycloakLogoutRedirectUrl } from '@/commons/helpers/LocalEnvData'
import { logout, selectIsInvalidToken } from '@/store/guardianSlice'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import useKeycloak from './useKeycloak'

const useAppAuth = () => {
  const dispatch = useAppDispatch()
  const isInvalidToken = useAppSelector(selectIsInvalidToken)
  const {
    error,
    initialized,
    isAuthenticated,
    parsedRefreshToken,
    signinRedirect,
    signoutRedirect,
    startSilentRenew,
    user,
  } = useKeycloak()

  // logout if the refresh token is expired
  useEffect(() => {
    if (
      parsedRefreshToken &&
      parsedRefreshToken.exp &&
      parsedRefreshToken.exp < Date.now() / 1000
    ) {
      dispatch(logout())
    }
  }, [dispatch, parsedRefreshToken])

  // logout if there is an error
  useEffect(() => {
    if (error) {
      dispatch(logout())
    }
  }, [error, dispatch])

  // auto signin
  useEffect(() => {
    if (initialized && !isAuthenticated) {
      signinRedirect()
    }
  }, [initialized, signinRedirect, isAuthenticated])

  // logout effect: all logouts must follow this flow
  useEffect(() => {
    if (isInvalidToken && user?.id_token) {
      signoutRedirect({
        post_logout_redirect_uri: keycloakLogoutRedirectUrl,
        id_token_hint: user?.id_token,
      })
    }
  }, [isInvalidToken, signoutRedirect, user?.id_token])

  // auto silent renew
  useEffect(() => {
    if (initialized && isAuthenticated) {
      startSilentRenew()
    }
  }, [initialized, isAuthenticated, startSilentRenew])
}

export default useAppAuth
