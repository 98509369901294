import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  HStack,
  chakra,
  IconButton,
  Icon,
  ModalBody,
  ModalFooter,
  Text,
} from '@chakra-ui/react'
import { BiX } from 'react-icons/bi'

type DirtyFormModalConfirmationProps = {
  handleSubmit: () => void
  hideSubmitButton?: boolean
  isOpen: boolean
  isSubmitting: boolean
  isValid: boolean
  onExitForm: () => void
  onKeepEditing: () => void
}

const DirtyFormModalConfirmation = ({
  handleSubmit,
  hideSubmitButton,
  isOpen,
  isSubmitting,
  isValid,
  onExitForm,
  onKeepEditing,
}: DirtyFormModalConfirmationProps) => (
  <Modal isOpen={isOpen} onClose={onKeepEditing} isCentered>
    <ModalOverlay />
    <ModalContent minWidth='515px'>
      <ModalHeader textTransform='capitalize'>
        <HStack justifyContent='space-between'>
          <chakra.span textTransform='none'>
            You have unsaved changes.
          </chakra.span>
          <IconButton
            aria-label='close modal'
            icon={<Icon as={BiX} boxSize='18px' />}
            onClick={onKeepEditing}
            variant='modal-close-button'
            isDisabled={isSubmitting}
          />
        </HStack>
      </ModalHeader>
      <ModalBody>
        <Text color='russianViolet.600'>
          If you navigate away the changes will be discarded.
        </Text>
        <Text color='russianViolet.600'>What would you like to do?</Text>
      </ModalBody>
      <ModalFooter>
        <HStack justifyContent='space-between' width='100%'>
          <Button
            variant='ghost'
            isDisabled={isSubmitting}
            onClick={onKeepEditing}
          >
            Go Back Editing
          </Button>
          <HStack>
            <Button
              variant='danger-ghost'
              isDisabled={isSubmitting}
              onClick={onExitForm}
            >
              Discard Changes
            </Button>
            {!hideSubmitButton && (
              <Button
                isLoading={isSubmitting}
                onClick={handleSubmit}
                isDisabled={!isValid}
              >
                Save Changes
              </Button>
            )}
          </HStack>
        </HStack>
      </ModalFooter>
    </ModalContent>
  </Modal>
)

export default DirtyFormModalConfirmation
