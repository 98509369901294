import {
  Popover,
  PopoverTrigger,
  Flex,
  Icon,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  PlacementWithLogical,
  Box,
} from '@chakra-ui/react'
import { BiHelpCircle } from 'react-icons/bi'

type HelpTextPopoverProps = {
  offset?: [number, number]
  placement?: PlacementWithLogical
  tooltipMessage?: string | React.ReactNode
}

const HelpTextPopover = ({
  offset = [-10, 10],
  placement = 'top-start',
  tooltipMessage,
}: HelpTextPopoverProps) => {
  if (!tooltipMessage) return <></>

  // the usage of box wrapping the Popover is a workaround to avoid warnings in the console
  // ref: https://github.com/chakra-ui/chakra-ui/issues/3440

  return (
    <Box>
      <Popover
        offset={offset}
        isLazy
        trigger='hover'
        placement={placement}
        variant='info'
        strategy='fixed'
      >
        <PopoverTrigger>
          <Flex cursor='pointer'>
            <Icon as={BiHelpCircle} boxSize='14px' color='iris.500' />
          </Flex>
        </PopoverTrigger>
        <PopoverContent borderWidth={0} width='fit-content' maxWidth='30vw'>
          <PopoverArrow backgroundColor='russianViolet.600' />
          <PopoverBody
            borderRadius='8px'
            color='white'
            fontSize='xs'
            fontWeight='normal'
            textTransform='none'
            whiteSpace='initial'
            lineHeight={1.5}
          >
            {tooltipMessage}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

export default HelpTextPopover
