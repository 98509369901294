import React, { ReactNode, useState } from 'react'
import {
  Box,
  Flex,
  GridItem,
  MenuItem,
  SimpleGrid,
  Tooltip,
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

import { clearNotificationAlert } from '@/commons/helpers'
import { AlertResponse } from '@/commons/interfaces'

type BaseNotificationItemProps = {
  alert: AlertResponse
  Avatar: ReactNode
  children: ReactNode
  redirectRoute: string
  refetch: () => void
}

const BaseNotificationItem = ({
  alert,
  Avatar,
  children,
  redirectRoute,
  refetch,
}: BaseNotificationItemProps) => {
  const history = useHistory()
  const [isClearing, setIsClearing] = useState(false)

  const handleClearAlert = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()
    if (!alert.id || isClearing) return
    setIsClearing(true)
    clearNotificationAlert(alert.id).finally(() => {
      refetch()
      setIsClearing(false)
    })
  }

  const handleClickItem = () => {
    history.push(redirectRoute)
    clearNotificationAlert(alert.id!).finally(refetch)
  }

  return (
    <MenuItem
      _focus={{
        backgroundColor: alert.checked ? 'white' : 'fluorescentBlue.50',
      }}
      _hover={{
        backgroundColor: '#F4FBFF',
      }}
      backgroundColor={alert.checked ? 'white' : 'fluorescentBlue.50'}
      onClick={handleClickItem}
      paddingLeft={3}
      paddingRight={2}
      paddingY={2}
      transition='background-color 0.5s'
    >
      <SimpleGrid columns={28} width='100%'>
        {Avatar}
        <GridItem colStart={6} colEnd={27}>
          {children}
        </GridItem>
        {!alert.checked && (
          <GridItem colSpan={2}>
            <Flex justify='flex-end'>
              <Tooltip
                label='Click to clear this notification'
                hasArrow
                placement='top-start'
                offset={[6, 10]}
              >
                <Box
                  _hover={{
                    backgroundColor: 'fluorescentBlue.600',
                  }}
                  width='8px'
                  height='8px'
                  borderRadius='full'
                  backgroundColor='fluorescentBlue.500'
                  marginRight='4px'
                  transition='opacity 0.2s'
                  onClick={handleClearAlert}
                />
              </Tooltip>
            </Flex>
          </GridItem>
        )}
      </SimpleGrid>
    </MenuItem>
  )
}

export default BaseNotificationItem
