const base64ToFile = (base64: string, type: string) => {
  try {
    const byteString = atob(base64)
    const arrayBuffer = new ArrayBuffer(byteString.length)
    const uInt8ArrayBuffer = new Uint8Array(arrayBuffer)
    for (let i = 0; i < byteString.length; i += 1) {
      uInt8ArrayBuffer[i] = byteString.charCodeAt(i)
    }
    const blob = new Blob([uInt8ArrayBuffer], {
      type,
    })
    return blob
  } catch (error) {
    console.error(error)
  }
}
export default base64ToFile
