import { HStack, Text } from '@chakra-ui/react'
import { JobSubmissionResponse } from '@/commons/interfaces'
import { SlurmJobStateValue } from '@/components/atoms'

type SlurmJobStateDataFieldProps = {
  data: JobSubmissionResponse
  label: string
}

const SlurmJobStateDataField = ({
  data,
  label,
}: SlurmJobStateDataFieldProps) => {
  return (
    <HStack spacing={2} justifyContent='flex-start'>
      <Text
        fontSize='sm'
        color='black'
        fontWeight='semibold'
        whiteSpace='nowrap'
      >
        {label}:
      </Text>
      <SlurmJobStateValue data={data} />
    </HStack>
  )
}

export default SlurmJobStateDataField
