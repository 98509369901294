import { useEffect } from 'react'
import { Flex, Table, Thead, Tr, Th, Tbody, Box, Icon } from '@chakra-ui/react'
import {
  useTable,
  useSortBy,
  TableOptions,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table'
import { BiUpArrowAlt, BiDownArrowAlt } from 'react-icons/bi'
import TableCell from '@/components/atoms/TableCell'
import {
  ExtendedHeaderGroup,
  TableProps,
  TableDialogContent,
} from '@/commons/interfaces/components/Table'

type TableComponentProps<TableData extends object> = Pick<
  TableProps<TableData>,
  | 'columns'
  | 'data'
  | 'error'
  | 'globalFilterValue'
  | 'hiddenColumns'
  | 'hideTableHeader'
  | 'isLoading'
  | 'onClickRow'
  | 'onSort'
  | 'refetch'
> & {
  onCloseDialog: () => void
  onOpenDialog: (content: TableDialogContent<TableData>) => void
  sortByInitialState?: Array<{ id: string; desc?: boolean }>
}

interface TableOptionsExtended<TableData extends object>
  extends TableOptions<TableData> {
  handleOpenDialog: (content: any) => void
  handleCloseDialog: () => void
  refetch: () => void
}

const TableComponent = <TableData extends object>({
  columns,
  data,
  globalFilterValue,
  hiddenColumns = [],
  hideTableHeader,
  onClickRow,
  onCloseDialog,
  onOpenDialog,
  onSort,
  refetch,
  sortByInitialState,
}: TableComponentProps<TableData>) => {
  const changeGlobalFilter = useAsyncDebounce(
    (globalFilterValue) => setGlobalFilter(globalFilterValue),
    300
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      handleOpenDialog: onOpenDialog,
      handleCloseDialog: onCloseDialog,
      refetch,
      manualSortBy: !!onSort,
      initialState: {
        hiddenColumns,
        sortBy: sortByInitialState ?? [],
      },
    } as TableOptionsExtended<TableData>,
    useGlobalFilter,
    useSortBy
  )

  useEffect(() => {
    changeGlobalFilter(globalFilterValue)
  }, [globalFilterValue, changeGlobalFilter])

  useEffect(() => {
    if (onSort) onSort(sortBy)
  }, [onSort, sortBy])

  return (
    <Box height='100%' overflow='auto' width='100%'>
      <Table {...getTableProps()}>
        <Thead display={hideTableHeader ? 'none' : 'auto'}>
          {headerGroups.map((headerGroup: ExtendedHeaderGroup<TableData>) => (
            <Tr
              {...headerGroup.getHeaderGroupProps()}
              backgroundColor='gray.50'
              borderRadius='6px'
            >
              {headerGroup.headers.map(
                (header: ExtendedHeaderGroup<TableData>) => {
                  const columnProps = header.getHeaderProps(
                    header.getSortByToggleProps({
                      title: 'Sort By',
                    })
                  )
                  return (
                    <Th
                      {...columnProps}
                      isNumeric={header.isNumeric}
                      height='40px'
                      verticalAlign='center'
                      paddingY={0}
                      whiteSpace='nowrap'
                      paddingRight={4}
                      color='gray.700'
                    >
                      <Flex
                        alignItems='center'
                        justifyContent={
                          header.isActionColumn ? 'flex-end' : 'unset'
                        }
                      >
                        {header.render('Header')}
                        {header.isSorted &&
                          (header.isSortedDesc ? (
                            <Icon
                              as={BiDownArrowAlt}
                              color='iris.500'
                              boxSize={3.5}
                              marginLeft={2}
                            />
                          ) : (
                            <Icon
                              as={BiUpArrowAlt}
                              color='iris.500'
                              boxSize={3.5}
                              marginLeft={2}
                            />
                          ))}
                      </Flex>
                    </Th>
                  )
                }
              )}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <Tr
                {...row.getRowProps()}
                _hover={{ backgroundColor: 'gray.50' }}
                cursor={onClickRow ? 'pointer' : 'default'}
                onClick={() => {
                  onClickRow && onClickRow(row, onOpenDialog)
                }}
              >
                {row.cells.map((cell, index) => (
                  <TableCell cell={cell} index={index} key={index} />
                ))}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Box>
  )
}

export default TableComponent
