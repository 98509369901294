import { SelectOption } from '../types'

type ConvertListToSelectOptionsArgs<T extends object> = {
  labelKey: keyof T
  valueKey: keyof T
}
const convertListToSelectOptions = <T extends object>(
  list: T[],
  { labelKey, valueKey }: ConvertListToSelectOptionsArgs<T>,
  extraFields?: (keyof T)[]
) => {
  const baseOptions = list.map<SelectOption>((item) => ({
    label: String(item[labelKey]),
    value: String(item[valueKey]),
  }))

  if (extraFields && extraFields.length > 0) {
    return baseOptions.map((option, index) => {
      const extraFieldsObject = extraFields.reduce(
        (acc, field) => ({ ...acc, [field]: list[index][field] }),
        {}
      )
      return { ...option, ...extraFieldsObject }
    })
  }

  return baseOptions
}

export default convertListToSelectOptions
