import {
  Box,
  Button,
  HStack,
  StackProps,
  Tooltip,
  TooltipProps,
} from '@chakra-ui/react'

type FormActionButtonsProps = {
  cancelLabel?: string
  cancelVariant?: string
  isDisabled?: boolean
  isSubmitting?: boolean
  onClickCancel: () => void
  onClickSubmit?: () => void
  stackProps?: StackProps
  submitButtonProps?: Omit<React.ComponentProps<typeof Button>, 'children'>
  submitLabel?: string
  submitType?: 'submit' | 'button' | 'reset'
  submitVariant?: string
  tooltipProps?: Omit<TooltipProps, 'children'>
}

const FormActionButtons = ({
  cancelLabel = 'Cancel',
  cancelVariant = 'ghost',
  isDisabled,
  isSubmitting,
  onClickCancel,
  onClickSubmit,
  stackProps,
  submitButtonProps,
  submitLabel = 'Save',
  submitType = 'submit',
  submitVariant = 'solid',
  tooltipProps,
}: FormActionButtonsProps) => (
  <HStack justifyContent='flex-end' {...stackProps}>
    <Button
      onClick={onClickCancel}
      isDisabled={isSubmitting}
      variant={cancelVariant}
    >
      {cancelLabel}
    </Button>
    <Tooltip
      hasArrow
      placement='top-end'
      isDisabled={!isDisabled || !tooltipProps?.label}
      {...tooltipProps}
    >
      <Box width='fit-content'>
        <Button
          {...submitButtonProps}
          isDisabled={isDisabled}
          isLoading={isSubmitting}
          onClick={onClickSubmit}
          type={submitType}
          variant={submitVariant}
        >
          {submitLabel}
        </Button>
      </Box>
    </Tooltip>
  </HStack>
)

export default FormActionButtons
